import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import * as React from "react";
import Box from "@mui/material/Box";
import { TbSearch } from "react-icons/tb";
import TransferBookings from "./TransferBooking";
import PackagesList from "./package/PackagesList";
import AttractionList from "./attraction/AttractionList";
import LandComboList from "./landcombos/LandComboList";
import AllList from "./All/All";
import CustomList from "./CustomBooking/CustomLIst";


const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  marginTop: "1%",
  marginLeft: open ? theme.spacing(29) : theme.spacing(7),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
  background: "rgba(204,204,204,0.2)",
  padding: "1rem 1rem 1rem 1rem",
}));

const Booking = ({ open }) => {
  const [value, setValue] = React.useState("All");
  const [searchText, setSearchText] = React.useState('');
  const [bookingStatus, setBookingStatus] = React.useState('');
  // const [filterButton, setFilterButton] = React.useState(true);

  const handleChange = (newValue) => {
    if(newValue === 'full'){
      setValue(newValue);
    }
    if (newValue === "All") {
      setValue(newValue);
    }
    if (newValue === "Transfers") {
      setValue(newValue);
    }
    if (newValue === "Packages") {
      setValue(newValue);
    }
    if (newValue === "Attraction") {
      setValue(newValue);
    }
    if (newValue === "LandCombos") {
      setValue(newValue);
    }
    if (newValue === "Custom") {
      setValue(newValue);
    }

  };

  const renderTable = () => {
    switch (value) {
      case "All":
        return <AllList searchText={searchText} bookingStatus={bookingStatus} />
      case "Transfers":
        return <TransferBookings searchText={searchText} bookingStatus={bookingStatus} />;
      case "Packages":
        return <PackagesList searchText={searchText} bookingStatus={bookingStatus} />;
      case "Attraction":
        return <AttractionList searchText={searchText} bookingStatus={bookingStatus} />;
      case "LandCombos":
        return <LandComboList searchText={searchText} bookingStatus={bookingStatus} />;
      case "Custom":
        return <CustomList searchText={searchText} bookingStatus={bookingStatus} />
      default:
        return;
    }
  };




  return (
    <StyledDashboardContainer container spacing={2} open={open}>
      <Box sx={{
        width: "100%", display: "flex",
        justifyContent: "space-between", padding: "20px",
        marginLeft: "1rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        alignItems: "center", background: "#fff"
      }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* <button style={{
            outline: 'none',
            background: 'none',
            border: 'none',
            // borderRadius:'20px',
            padding: '0',
            cursor: 'pointer',
            fontWeight: filterButton ? '800' : '400',
            borderBottom: filterButton ? '2px solid #103083' : 'none'
          }}
            onClick={() => {
              setBookingStatus('');
              setFilterButton(true);
            }}
          >All</button> */}
          {/* <button style={{
            outline:'none',
            background:'none',
            border:'none',
            // borderRadius:'20px',
            padding:'0 10px',
            cursor:'pointer',
            fontWeight:filterButton ? '400' : '800',
            borderBottom:filterButton ? "none" : '2px solid #103083'
            }}
            onClick={()=>{
              setBookingStatus('Pending');
              setFilterButton(false);
            }}
            >Pending</button> */}

          <select 
          style={{
            outline:'none',
            border:'1px solid skyblue',
            borderRadius:'20px',
            paddingLeft:'10px',
            fontSize: "16px", 
            color: "#00081d",
          }}
          
          value={bookingStatus}
            onChange={(e) => {
              setBookingStatus(e.target.value);
              // setFilterButton(false);
            }}>
            <option value="" disabled>Booking Status</option>
            <option value="All" >All</option>
            <option value="Confirmed" >Confirmed</option>
            <option value="On Hold" >On Hold</option>
            <option value="Pending" >Pending</option>
            <option value="Awaiting Payment" >Awaiting Payment</option>
            <option value="Rejected" >Rejected</option>
          </select>
          <div className="search-from-list">
            <input style={{ border: "none", outline: "none", background: "none" }}
              type="text" placeholder="Search..." onChange={(e) => {
                e.preventDefault();
                setSearchText(e.target.value)
              }} />
            <TbSearch style={{ color: "skyblue" }} />
          </div>
        </div>

        <select style={{
          width: "fit-content", border: "1px solid skyblue",
          padding: " 5px 10px", borderRadius: "20px", fontSize: "16px", color: "#00081d", outline: "none"
        }}
          onChange={(e) => {
            renderTable(e.target.value);
            handleChange(e.target.value);
          }}
        >
          <option value="full" disabled>Service Type</option>
          <option value="All">All</option>
          <option value="Packages">Packages</option>
          <option value="Attraction">Attraction</option>
          <option value="LandCombos">LandCombos</option>
          <option value="Transfers">Transfers</option>
          <option value="Custom">Custom</option>
        </select>
      </Box>
      {
        renderTable()
      }
    </StyledDashboardContainer>
  );
};

export default Booking;
