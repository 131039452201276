import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import './Packagelist.css';
import { Paper } from "@mui/material";
import { token, APIPath } from "../../config/api";
import PackageDetail from "./PackagePreview";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  width: open ? "80%" : "94%",
  transition: "width 0.3s ease",
  paddingTop: "7px",
  marginLeft: open ? "243px" : "69px",
  position: "relative",
  borderRadius: "0px",
  overflow: "hidden",
  marginBottom: "20px",
  boxShadow: "none",
}));

const CreatePackageForm = ({ open, close, loadPackagesList, packageEditedId,
  titleP,
  descriptionP,
  imageCoverP, imageBannerP,
  categoryP, categoryNameP,
  durationP, durationNameP,
  inclusionP, exclusionP,
  cancellationRefundPolicyP,
  countryName,cityName,
  dayWisePlanP,
  adultPriceP, childrenPriceP,
  currencyP
}) => {
  document.body.style.overflow = 'hidden';

  const [title, setTitle] = React.useState(titleP || '');
  const [description, setDescription] = React.useState(descriptionP || '');
  const [imageCover, setImageCover] = React.useState(imageCoverP || '');
  const [coverPreview, setCoverPreview] = React.useState('')
  const [imageBanner, setImageBanner] = React.useState(imageBannerP || '');
  const [bannerPreview, setBannerPreview] = React.useState('');
  const [country, setCountry] = React.useState(null)
  const [fromCountry, setFromCountry] = React.useState('');
  const [fromCountryName, setFromCountryName] = React.useState('');
  const [toCountry, setToCountry] = React.useState('');
  const [toCountryName, setToCountryName] = React.useState('');
  const [type, setType] = React.useState('');
  const [categoryData, setCategoryData] = React.useState(null);
  const [category, setCategory] = React.useState(categoryP || '');
  const [categoryName, setCategoryName] = React.useState(categoryNameP || '')
  const [city, setCity] = React.useState(null);
  const [place, setPlace] = React.useState('');
  const [placeName, setPlaceName] = React.useState('');
  const [duration, setDuration] = React.useState(durationP || '');
  const [durationName, setDurationName] = React.useState(durationP || '');
  const [inclusions, setInclusions] = React.useState(inclusionP || ['']);
  const [exclusions, setExclusions] = React.useState(exclusionP || ['']);
  const [daywisePlan, setDaywisePlan] = React.useState(dayWisePlanP || []);
  const [adultsPrice, setAdultsPrice] = React.useState(adultPriceP || '');
  const [childPrice, setChildPrice] = React.useState(childrenPriceP || '')
  const [currency, setCurrency] = React.useState(currencyP || '');
  const [cancellationPolicy, setCancellationPolicy] = React.useState(cancellationRefundPolicyP || '');

  const titleFromDetails = titleP;

  const Id = "65b20828d1cdfbe9749eb653";
  React.useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/country?continent=${Id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setCountry(data.data);
      })
      .catch((err) => alert(err));

  }, [])

  React.useEffect(() => {
    if (fromCountry) {
      fetch(`${APIPath}/api/v1/agency/city?country=${fromCountry}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setCity(data.data);
        })
        .catch((err) => alert(err));
    }
  }, [fromCountry])

  const handleImageChangeCover = (e) => {
    const file = e.target.files[0];
    setImageCover(file);
    setCoverPreview(URL.createObjectURL(file));
  };
  React.useEffect(() => {
    const convertUrlToFile = async () => {
      if (imageCoverP) {
        const response = await fetch(imageCoverP);
        const blob = await response.blob();
        const fileName = imageCoverP.split('/').pop(); // Get the file name from the URL
        const newFile = new File([blob], fileName, { type: blob.type });

        setImageCover(newFile);
        setCoverPreview(URL.createObjectURL(newFile));
      }
    };
    convertUrlToFile();
  }, [imageCoverP]);

  React.useEffect(() => {
    const convertUrlToFile = async () => {
      if (imageBannerP) {
        const response = await fetch(imageBannerP);
        const blob = await response.blob();
        const fileName = imageBannerP.split('/').pop(); // Get the file name from the URL
        const newFile = new File([blob], fileName, { type: blob.type });

        setImageBanner(newFile);
        setBannerPreview(URL.createObjectURL(newFile));
      }
    };
    convertUrlToFile();
  }, [imageBannerP]);

  const handleImageChangeBanner = (e) => {
    const file = e.target.files[0];
    setImageBanner(file);
    setBannerPreview(URL.createObjectURL(file))
  };

  const handleCategoryChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setCategory(e.target.value);
    setCategoryName(selectedCategoryName);
  };
  const handleFromCountryChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setFromCountry(e.target.value);
    setFromCountryName(selectedCategoryName);
  };
  // const handleToCountryChange = (e) => {
  //   const selectedIndex = e.target.options.selectedIndex;
  //   const selectedCategoryName = e.target.options[selectedIndex].text;
  //   setToCountry(e.target.value);
  //   setToCountryName(selectedCategoryName);
  // };
  const handlePlaceChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setPlace(e.target.value);
    setPlaceName(selectedCategoryName);
  };

  const handleDurationChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedDurationName = e.target.options[selectedIndex].text;
    setDuration(e.target.value)
    setDurationName(selectedDurationName)
  }
  React.useEffect(() => {
    fetch(`${APIPath}/api/v1/categories?type=package`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data)
      })
      .catch((error) => {
        alert('Error fetching Category list:', error);

      });
  }, [])

  const handleAddInclusion = () => {
    setInclusions([...inclusions, '']);
  };

  const handleAddExclusion = () => {
    setExclusions([...exclusions, '']);
  };

  const handleInclusionChange = (index, event) => {
    const newInclusions = [...inclusions];
    newInclusions[index] = event.target.value;
    setInclusions(newInclusions);
  };

  const handleExclusionChange = (index, event) => {
    const newExclusions = [...exclusions];
    newExclusions[index] = event.target.value;
    setExclusions(newExclusions);
  };

  React.useEffect(() => {
    if (duration) {
      const days = parseInt(duration.split("/")[1].split(" ")[1], 10);
      const newDaywisePlan = Array.from({ length: days }, (_, index) => ({
        title: `Day ${index + 1}`,
        description: dayWisePlanP?.[index]?.description || ''
      }));
      setDaywisePlan(newDaywisePlan);
    }
  }, [duration]);

  const handleDaywisePlanChange = (dayIndex, event) => {
    const newDaywisePlan = [...daywisePlan];
    newDaywisePlan[dayIndex] = {
      title: `Day ${dayIndex + 1}`,
      description: event.target.value
    };
    setDaywisePlan(newDaywisePlan);
  };

  const [previewPage, setPreviewPage] = React.useState(false);
  const previewPageOpen = () => {
    setPreviewPage(true);
    console.log("cancellationPolicy----",cancellationPolicy)
  }
  const previewPageClose = () => {
    setPreviewPage(false)
  }

  const Uppercase = (str) => str?.toUpperCase();
  const capitalize = (str) => {
      if (typeof str !== 'string') return '';
      return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
    };
  return (
    <StyledTableContainer component={Paper} open={open}>
      <div className="admin-package-detail-container">
        <div className="admin-package-detail-page"
          style={{ background: "rgba(249, 249, 249, 1)" }}
        >
          <div className="back-and-text">
            <div className="back">
              <img src="backicon.svg" alt="back-icon" onClick={close} />
            </div>
            <div className="text">
              <h2>Add New Package</h2>
            </div>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            previewPageOpen();
          }}>
            <div className="package-basic-details">
              <h3>Basic Details</h3>
              <div className="package-form-title-description">
                <div className="package-form-title">
                  <label>Title *</label>
                  <input type="text" placeholder="magical dubai" maxLength={60} value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="package-form-description">
                  <label>Description *</label>
                  <textarea type="text" placeholder="Very luxurious and comfortable" value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    required style={{ height: "100px", overflow: "hidden" }}
                  />
                </div>
              </div>
            </div>
            <div className="package-form-cover-banner-img">
              <div className="package-form-cover-img">
                <label>Add Cover Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeCover}
                  required={!imageCoverP}
                />
                {coverPreview && <div className="package-form-img">
                  <img src={coverPreview} alt="coverPreview" />
                </div>
                }
              </div>
              <div className="package-form-banner-img">
                <label>Add Banner Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeBanner}
                  required={!imageBannerP}
                />
                {bannerPreview && <div className="package-form-img">
                  <img src={bannerPreview} alt="bannerPreview" />
                </div>
                }
              </div>
            </div>
            <div className="package-form-location-details">
              <h2>Location Details</h2>
              <div className="package-form-country-place">
                <div className="package-form-from-country" style={{ width: "50%" }}>
                  <label>Country</label>
                  <select value={fromCountry} onChange={handleFromCountryChange} required>
                    <option value="" disabled>Select Country</option>
                    {country?.map((val, id) => {
                      return <>
                        <option value={val._id}>{Uppercase(val.name)}</option>
                      </>
                    })}
                  </select>
                </div>
                {/* <div className="package-form-from-country">
                  <label>To Country</label>
                  <select value={toCountry} onChange={handleToCountryChange} required>
                    <option value="" disabled>Select Country</option>
                    {country?.map((val, id) => {
                      return <>
                        <option value={val._id}>{val.name}</option>
                      </>
                    })}
                  </select>
                </div> */}
                <div className="package-form-from-country" style={{ width: "50%" }}>
                  <label>City</label>
                  <select value={place} onChange={handlePlaceChange} required>
                    <option value="" disabled>Select city</option>
                    {city?.map((val, id) => {
                      return <>
                        <option value={val._id}>{capitalize(val.name)}</option>
                      </>
                    })}
                  </select>
                </div>

              </div>
            </div>
            <div className="package-form-location-details">
              <h2>Package Type</h2>
              <div className="package-form-country-place">
                {/* <div className="package-form-from-country">
                  <label>Type</label>
                  <select value={type} onChange={(e) => {
                    setType(e.target.value);
                  }} required>
                    <option value="" disabled>Select Type</option>
                    <option value="Luxury">Luxury</option>
                    <option value="Standard">Standard</option>
                  </select>
                </div> */}
                <div className="package-form-from-country">
                  <label>Category *</label>
                  <select value={category} onChange={handleCategoryChange} required>
                    <option value="" disabled>Select Category</option>
                    {categoryData?.map((val, id) => {
                      return <>
                        <option value={val._id}>{val.name}</option>
                      </>
                    })}
                  </select>
                </div>
                <div className="package-form-from-country">
                  <label>Duration *</label>
                  <select value={duration}
                    onChange={handleDurationChange}
                    required
                  > <option value={durationP ? durationP : ""}>{durationP ? durationP : "Select Duration "}</option>
                    <option value="2 Nights/ 3 Days">2 Nights/ 3 Days</option>
                    <option value="3 Nights/ 4 Days">3 Nights/ 4 Days</option>
                    <option value="4 Nights/ 5 Days">4 Nights/ 5 Days</option>
                    <option value="5 Nights/ 6 Days">5 Nights/ 6 Days</option>
                    <option value="6 Nights/ 7 Days">6 Nights/ 7 Days</option>
                  </select>
                </div>

              </div>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Cancellation Policy</h2>
              <div className="package-form-cancellation-polocy">
                <textarea
                  value={cancellationPolicy?.length > 0 ? cancellationPolicy?.join("\n") :cancellationPolicy} 
                  placeholder="Write Cancellation Policy"
                  onChange={(e) => {
                    // setCancellationPolicy(e.target.value);
                    setCancellationPolicy(e.target.value.split("\n"));
                  }}
                />
              </div>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Inclusion/Exclusions</h2>
              <div className="package-form-inclusion-exclusion-container">
                <div className="package-form-inclusion">
                  <label>Inclusions *</label>
                  {inclusions.map((inclusion, index) => (
                    <input
                      key={index}
                      type="text"
                      value={inclusion}
                      onChange={(event) => handleInclusionChange(index, event)}
                      required
                    />
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddInclusion}
                  ><span>+</span> Add more</p>
                </div>
                <div className="package-form-inclusion">
                  <label>Exclusions *</label>
                  {exclusions.map((exclusion, index) => (
                    <input
                      key={index}
                      type="text"
                      value={exclusion}
                      onChange={(event) => handleExclusionChange(index, event)}
                      required
                    />
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddExclusion}
                  ><span>+</span> Add more</p>
                </div>
              </div>
            </div>
            <div className="package-form-itinerary-container">
              <h2>Itinerary Details</h2>
              {daywisePlan?.map((day, dayIndex) => {
                return <>
                  <div key={dayIndex} className="package-form-itinerary">
                    <h3>Day {dayIndex + 1}</h3>
                    <textarea
                      type="text"
                      value={day.description}
                      onChange={(event) => {
                        handleDaywisePlanChange(dayIndex, event)
                      }}
                      required
                    />
                  </div>
                </>
              })}
            </div>
            <div className="package-form-price">
              <h2>Pricing</h2>
              <div style={{ display: "flex", gap: "4rem" }}>
                <div>
                  <label>Adults Price</label>
                  <div className="package-form-price-value">
                    <select value={currency} onChange={(e) => {
                      setCurrency(e.target.value)
                    }} required
                      style={{ width: "100px" }}
                    >
                      {/* <option value="" disabled>Currency</option> */}
                      <option value="AED">AED</option>
                      {/* <option value="INR">INR</option>
                    <option value="USD">USD</option> */}
                    </select>
                    <input type="number" placeholder="1200" value={adultsPrice} onChange={(e) => {
                      setAdultsPrice(e.target.value);
                    }} required />
                  </div>
                </div>
                <div>
                  <label>Children Price</label>
                  <div className="package-form-price-value">
                    <select value={currency} onChange={(e) => {
                      setCurrency(e.target.value)
                    }} required
                      style={{ width: "100px" }}
                    >
                      {/* <option value="" disabled>Currency</option> */}
                      <option value="AED">AED</option>
                      {/* <option value="INR">INR</option>
                    <option value="USD">USD</option> */}
                    </select>
                    <input type="number" placeholder="1200" value={childPrice} onChange={(e) => {
                      setChildPrice(e.target.value);
                    }} required />
                  </div>
                </div>
              </div>

            </div>
            <div className="package-form-back-next-btn">
              <button id="back" onClick={close}>Back</button>
              <button id="next"
              // onClick={() => {

              // }}
              >Next</button>
            </div>
          </form>
        </div>
      </div>
      {previewPage && <PackageDetail close={previewPageClose} closeform={close} titleFromDetails={titleFromDetails}
        title={title} description={description} packageEditedId={packageEditedId}
        imageCoverP={coverPreview} imageBannerP={bannerPreview}
        toCountry={toCountry} toCountryName={toCountryName} fromCountry={fromCountry} fromCountryName={fromCountryName}
        place={place} placeName={placeName}
        type={type} category={category} categoryName={categoryName} duration={duration} durationName={durationName}
        inclusion={inclusions} exclusion={exclusions}
        dayWisePlan={daywisePlan}
        currency={currency} adultsprice={adultsPrice} childPrice={childPrice} loadPackagesList={loadPackagesList}
        cancellationPolicy={cancellationPolicy}
      />}
    </StyledTableContainer >
  );
};

export default CreatePackageForm;


// -------------------------------------------



