import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TransferForm from "./TransferForm"
import toast, { Toaster } from "react-hot-toast";
import { IoIosSearch } from "react-icons/io";
import { Button, Paper, Stack, Pagination, } from "@mui/material";
import { LuEye } from "react-icons/lu";
import { Link } from "react-router-dom";
import { token, APIPath } from "../../config/api";
import TransferDetails from "./TransferDetails";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  marginTop: "1%",
  marginLeft: open ? theme.spacing(29) : theme.spacing(7),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
  background: "rgba(204,204,204,0.2)",
  padding: "1rem 1rem 1rem 1rem",
  boxShadow: "none",
}));
const StyledPaginationContainer = styled("div")({
  margin: "2rem auto 0 auto;",
  zIndex: 1,
  background: "none",
  display: "flex",
  justifyContent: "center"
});
const TransferList = ({ open }) => {
  document.body.style.overflow = "auto";
  const [transfer, setTransfer] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCreateFormOpen, setCreateFormOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [searchText, setsearchText] = React.useState('');

  const loadTransferList = () => {
    fetch(`${APIPath}/api/v1/agency/transfers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data);
        setTransfer(data.data);
        setIsLoading(false)
      })
      .catch((error) => {
        alert('Error fetching Transfer list:', error);
        setIsLoading(false)
      });
  }
  React.useEffect(() => {
    loadTransferList()
  }, [])

  const handleCreateFormOpen = () => {
    setCreateFormOpen(true);
  };
  const handleCreateFormClose = () => {
    setCreateFormOpen(false);
    loadTransferList()
  };

  const filterdata =transfer && transfer?.filter(item =>
    item.pickup?.toLowerCase().includes(searchText.toLowerCase()) ||
    item.dropoff?.toLowerCase().includes(searchText.toLowerCase())
  );

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = filterdata && filterdata?.slice(indexOfFirstItem, indexOfLastItem);

  

  const [trnsDetailsPage, settrnsDetailsPage] = React.useState(false);
  const [selectedTransferId, setSelectedTransferId] = React.useState('');
  const [transferDetails, setTransferDetails] = React.useState(null);

  const selectTransfer = (id) => {
    setSelectedTransferId(id);
    settrnsDetailsPage(true);
  }
  const closeTransfer = () => {
    settrnsDetailsPage(false);
  }
  const Uppercase = (str) => str.toUpperCase();
  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <StyledTableContainer component={Paper} open={open}>
      {isCreateFormOpen && (
        <TransferForm
          isOpen={isCreateFormOpen}
          onClose={handleCreateFormClose}
        />
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{
          marginBottom: 14,
          marginTop: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <h2 style={{ fontSize: "18px" }}
        >Transfer</h2>
        <Button
          variant="contained"
          onClick={handleCreateFormOpen}
          color="primary"
          sx={{
            backgroundColor: "#2E90FA",
            color: "white",
            borderRadius: "20px",
            padding: "2px 16px"
          }}
        >
          Add Transfer
        </Button>
      </div>
      <div className="package-search-table">
        <div className="package-search">
          <input type="text" placeholder="Search.." value={searchText} onChange={(e) => {
            setsearchText(e.target.value)
          }} />
          <IoIosSearch style={{ cursor: "pointer", color: "skyblue" }} />
        </div>
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <>
            {currentItems ? (
              <div className="package-table">
                <table>
                  <thead>
                    <tr>
                      {/* <th>Sl. No.</th> */}
                      <th>Id</th>
                      <th>Type</th>
                      {/* <th>Service </th> */}
                      <th>PickUp City</th>
                      <th>PickUp</th>
                      <th>Drop Off</th>
                      {/* <th>Vehicle Name</th> */}
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((val, id) => (
                      <tr key={id}>
                        {/* <td>{(indexOfFirstItem + id + 1)}</td> */}
                        <td>{val.transferID}</td>
                        <td>{val?.transferType}</td>
                        {/* <td>{val?.type}</td> */}
                        <td>{capitalize(val?.cityName)}</td>
                        <td>{capitalize(val?.pickup)}</td>
                        <td>{capitalize(val?.dropoff)}</td>
                        {/* <td>{val?.vehicle.name}</td> */}
                        <td><b style={{ color: "#00081d" }}>AED {val?.cost}</b></td>
                        <td>
                          {/* <button id="trnasfer-action-btn"
                            onClick={() => {
                              selectTransfer(val._id);
                              setTransferDetails(val)
                            }}
                          >
                          </button> */}
                          <Link to="#" onClick={() => {
                              selectTransfer(val._id);
                              setTransferDetails(val)
                            }}> view more </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <h2>No data found</h2>
              </div>
            )}
          </>
        )}
      </div>
      <StyledPaginationContainer>
        <Stack spacing={1}>
          <Pagination
            count={Math.ceil((transfer && transfer.length) / perPage)}
            // count={1}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            color="primary"
            boundaryCount={1}
          />
        </Stack>
      </StyledPaginationContainer>
      {trnsDetailsPage && <TransferDetails close={closeTransfer} transferId={selectedTransferId}
        transferDetails={transferDetails} loadTransferList={loadTransferList}
      />}
    </StyledTableContainer>
  );
};

export default TransferList;



