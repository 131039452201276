import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { APIPath, token } from "../../../config/api";
import axios from 'axios';

const BookPackageDetails = ({ onClose, bookingId }) => {
    document.body.style.overflow = 'hidden';
    const [bookingDetails, setBookingDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookingStatus, setBookingStatus] = useState('')
    const [gatewayType, setGatewayType] = useState("STRIPE");
    const [rejection, setRejection] = useState(false);
    const [rejectionText, setRejectionText] = useState("");
    const [onhold, setOnhold] = useState(false);
    const [onholdText, setOnholdText] = useState("");
    const [status, setStatus] = useState('');

    useEffect(() => {
        fetch(`${APIPath}/api/v1/agency/bookings/packages?id=${bookingId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setBookingStatus(data.data[0].bookingStatus)
                setBookingDetails(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Package list:', error);
                setIsLoading(false);
            });
    }, [bookingId])
    const [loading, setLoading] = useState(false);
    const [count, SetResendCount] = useState(0);
    const sendPaymentLink = () => {
        setLoading(true);
        if (bookingStatus === 'Confirmed') {
            alert("Booking already Confirmed")
            return;
        }
        if (count >= 3) {
            alert("Payment link already sent 3 times. Please check your email or contact support for assistance.");
            return;
        }
        fetch(
            `${APIPath}/api/v1/agency/payments/transfer/payment-link?id=${bookingId}&gatewayType=${gatewayType}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                method: "POST",
                mode: "cors",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                alert(data.message);
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                alert(err);
                setLoading(false);
            });
    }
    const reasonClose = () => {
        setRejection(false);
        setOnhold(false)
        setStatus('');
    }
    const rejectRequest = () => {
        console.log("Request Rejected ")
        setRejection(true)
        setStatus("Rejected");
    }
    let reasonMsg = {
        status: status,
        reason: status === "Rejected" ? rejectionText : onholdText,
    }
    const rejectBooking = () => {
        if (rejectionText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/packages?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        alert("You are Rejecting this Request");
                        setRejectionText("");
                        reasonClose();
                        onClose()
                    }
                })
                .catch((error) => {
                    console.error('Error While Rejecting:', error);
                });
        } else {
            alert("Please provide valid 'Rejection' Reason");
            return;
        }
    }
    const onHoldRequest = () => {
        console.log("request On Hold");
        setOnhold(true)
        setStatus("On Hold")
    }

    const onHoldBooking = () => {
        if (onholdText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/packages?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        alert("You are Putting this request 'On Hold'.");
                        setOnholdText("");
                        reasonClose();
                        onClose();
                    }
                })
                .catch((error) => {
                    console.error('Error While put On Hold:', error);
                });
        } else {
            alert("Please provide valid 'On Hold' Reason");
            return;
        }
    }

    return <>
        <div className="booking-details-container">
            <div className="booking-details-page">
                {isLoading ? (
                    <div className="loader">
                    </div>) : (
                    <>
                        <div className="booking-pkg-pass-header">
                            <h4>Booking Details</h4>
                            <h2 onClick={onClose}><IoMdClose /></h2>
                        </div>
                        <div className="booking-pkg-package-details">
                            <h2 style={{ color: "rgb(14 76 237)" }}>Package Name:&nbsp;</h2>
                            <h2> &nbsp;{bookingDetails?.[0].packages?.[0].title} &nbsp;
                              <span style={{fontSize:'14px',fontWeight:'500'}}>({bookingDetails?.[0].packages?.[0].duration} )</span>
                            </h2>
                        </div>
                        <hr />
                        <div className="booking-pkg-pass-details">
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Name</h4>
                                <p>{bookingDetails?.[0].customerDetails.name}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Mobile No.</h4>
                                <p>+{bookingDetails?.[0].customerDetails.phone}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Email</h4>
                                <p>{bookingDetails?.[0].customerDetails.email}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="booking-pkg-pass-details">
                            <div className="booking-pkg-pass-name">
                                <h4>Adults</h4>
                                <p>{bookingDetails[0].packages?.[0].numberOfAdults}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Children</h4>
                                <p>{bookingDetails[0].packages?.[0].numberOfChildrens}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Infants</h4>
                                <p>{bookingDetails[0].packages?.[0].numberOfInfants}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Start Date</h4>
                                <p>{bookingDetails[0].packages?.[0].startDate?.split("T")[0]}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="booking-pkg-agent-details">
                            <div className="booking-pkg-agent-name">
                                <h4>Agent Name</h4>
                                <p>{bookingDetails[0].agentName}</p>
                            </div>
                            <div className="booking-pkg-agent-name">
                                <h4>Mobile No.</h4>
                                <p>+{bookingDetails[0].agentPhoneNumber}</p>
                            </div>
                            <div className="booking-pkg-agent-name">
                                <h4>Email</h4>
                                <p>{bookingDetails[0].agentEmail}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="choose-paymentgatway" style={{ padding: "0 2rem" }} >
                            <h4>Select Payment Gateway</h4>
                            <select className="payment-select"
                                style={{
                                }}
                                onChange={(e) => {
                                    setGatewayType(e.target.value)
                                }}>
                                <option disabled >Select Gateway</option>
                                <option value="STRIPE"> STRIPE</option>
                                <option value="TELR">TELR</option>
                            </select>
                        </div>
                        <div className="booking-pkg-price-and-actions">
                            <div className="booking-pkg-price">
                                <h2><span>Total cost:&nbsp;AED </span><b>{bookingDetails[0].totalCost}</b>
                                    {/* <sub style={{ fontSize: "12px" }}> +VAT(5%)</sub> */}
                                </h2>
                            </div>
                            <div className="booking-pkg-actions">
                                <button id="reject"
                                    onClick={rejectRequest}
                                    disabled={(bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    )}
                                    className={(bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    ) ? "intensity" : ""}
                                >Reject</button>
                                <button id="on-hold"
                                    onClick={onHoldRequest}
                                    disabled={(bookingStatus === 'On Hold' ||
                                        bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    )}
                                    className={(bookingStatus === 'On Hold' ||
                                        bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed'  
                                        // bookingStatus === 'Awaiting Payment'
                                    ) ? "intensity" : ""}
                                > Hold </button>

                                {loading ? <div className="loader">

                                </div> :
                                    <>
                                        {(bookingStatus === "Pending" || bookingStatus === "On Hold") &&
                                            <button id="send-link"
                                                onClick={sendPaymentLink}
                                            >
                                                Send Payment Link
                                            </button>
                                        }
                                        {bookingStatus === "Awaiting Payment" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    sendPaymentLink();
                                                    SetResendCount(count + 1);
                                                }}
                                            >
                                                Resend Payment Link
                                            </button>
                                        }
                                        {bookingStatus === "Confirmed" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    alert("Payment already received for this booking");
                                                    return;
                                                }}
                                            >
                                                Payment Received
                                            </button>
                                        }
                                        {bookingStatus === "Rejected" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    alert("This request has been rejected");
                                                    return;
                                                }}
                                            >
                                                Booking Rejected
                                            </button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
        {rejection && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for Rejection</p>
                            <p id="cencel" onClick={reasonClose}> <IoMdClose /></p>
                        </div>
                        <div className="reason-details">
                            <textarea
                                placeholder="Write Reason..."
                                value={rejectionText}
                                onChange={(e) => {
                                    setRejectionText(e.target.value);
                                }}
                            />
                        </div>
                        <div className="reason-button">
                            <button
                                onClick={() => {
                                    console.log("Rejection Reason");
                                    rejectBooking();
                                }}
                            >
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )}
        {onhold && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for On Hold</p>
                            <p id="cencel" onClick={reasonClose}> <IoMdClose /></p>
                        </div>
                        <div className="reason-details">
                            <textarea
                                placeholder="Write Reason..."
                                value={onholdText}
                                onChange={(e) => {
                                    setOnholdText(e.target.value);
                                }}
                            />
                        </div>
                        <div className="reason-button">
                            <button
                                onClick={() => {
                                    onHoldBooking();
                                }}
                            >
                                On Hold
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
}
export default BookPackageDetails;