import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import './Packagelist.css';

import {
    Paper,
} from "@mui/material";
import { APIPath, token } from "../../config/api";

import CreatePackageForm from "./CreatePackageForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    borderRadius: "0px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none"
}));

const PackageDetails = ({ open, close, pkgId, loadPackagesList }) => {
    document.body.style.overflow = 'hidden';
    const [packageData, setPackageData] = React.useState(null);
    const [packagePreview, setPackagePreview] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const loadPackagesDetails = () => {
        fetch(`${APIPath}/api/v1/packages?id=${pkgId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setPackageData(data.data);
                console.log(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                alert('Error fetching  Package Details:', error);
                setIsLoading(false);
            });
    }
    React.useEffect(() => {
        loadPackagesDetails();
    }, [])
    const DeletePackage = () => {
        let confirm = window.confirm("Are you sure you want to delete this Package?");
        if (confirm) {
            console.log("User confirmed deletion.");
            fetch(`${APIPath}/api/v1/agency/packages?id=${pkgId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
                mode: "cors",
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data.data);
                    close();
                    loadPackagesList();
                })
                .catch((error) => {
                    alert('Error while deleting Attraction...', error);
                });
        } else {
            return
        }
    }

    return <StyledTableContainer component={Paper} open={open}>
        <div className="admin-package-detail-container">
            <div className="admin-package-detail-page">
                {isLoading ? (
                    <div className="loader"></div>
                ) : (<>
                    <div className="back-and-text">
                        <div className="back">
                            <img src="backicon.svg" alt="back-icon" onClick={close} />
                        </div>
                        <div className="text">
                            <h2>Package Details</h2>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Basic Details</h3>
                        <div className="admin-pkg-title-description">
                            <div className="title">
                                <h4>Title:</h4>
                                <h5>{packageData[0]?.title}</h5>
                            </div>
                            {/* <div className="title-value">
                                <h5>{packageData[0]?.title}</h5>
                            </div> */}
                            <div className="title">
                                <h4>Description:</h4>
                                <h5>{packageData[0]?.packageOverview}</h5>
                            </div>
                            {/* <div className="description-value">
                                <h5>{packageData[0]?.packageOverview}</h5>
                            </div> */}
                        </div>
                        <div className="admin-pkg-title-description-pic">
                            <div className="title">
                                <h4>Cover Photo</h4>
                            </div>
                            <div className="title-value">
                                <img src={packageData[0]?.bannerImage} height="100px" width="150px" />
                            </div>
                            <div className="description">
                                <h4>Banner Photo</h4>
                            </div>
                            <div className="description-value">
                                <img src={packageData[0]?.thumbnailImage} height="100px" width="150px" />
                            </div>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Location Details</h3>
                        <div className="location-details">
                            <div className="title">
                                <h4>Country</h4>
                            </div>
                            <div className="title-value">
                                <h5>{packageData[0]?.location?.country}</h5>
                            </div>
                            <div className="description">
                                <h4>City</h4>
                            </div>
                            <div className="description-value">
                                <h5>{packageData[0]?.location?.city}</h5>
                            </div>
                            {/* <div className="description">
                                <h4>Places&nbsp;Included</h4>
                            </div>
                            <div className="description-value">
                                <h5>Burj Khalifa</h5>
                            </div> */}
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Package Type</h3>
                        <div className="location-details">
                            {/* <div className="title">
                                <h4>Type:</h4>
                            </div>
                            <div className="title-value">
                                <h5>Luxury</h5>
                            </div> */}
                            <div className="description">
                                <h4>Category:</h4>
                            </div>
                            <div className="description-value">
                                <h5>{packageData[0]?.categoryName}</h5>
                            </div>
                            <div className="description">
                                <h4>Duration:</h4>
                            </div>
                            <div className="description-value">
                                <h5>{packageData[0]?.duration}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="package-form-inclusion-exclusion">
                            <h2>Cancellation Policy</h2>
                            <div className="package-form-cancellation-polocy">
                                {packageData[0]?.cancellationRefundPolicy?.map((val, id) => {
                                    return <ul key={id}>
                                        <li>{val}</li>
                                    </ul>
                                })}
                            </div>
                        </div>
                    <div className="package-basic-details">
                        <h3>Inclusions/Exclusions</h3>
                        <div className="location-details">
                            <div className="title">
                                <h4>Inclusions:</h4>
                            </div>
                            <div className="title-value">
                                {packageData[0]?.includedServices?.map((val, id) => {
                                    return <>
                                        <ol>
                                            <p>{val}</p>
                                        </ol>
                                    </>
                                })}
                            </div>
                            <div className="description">
                                <h4>Exclusions:</h4>
                            </div>
                            <div className="description-value">
                                {packageData[0]?.excludedServices?.map((val, id) => {
                                    return <>
                                        <ol>
                                            <p>{val}</p>
                                        </ol>
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Detail Itinerary</h3>
                        {packageData[0]?.dayWisePlan?.map((val, id) => {
                            return <>
                                <div className="location-details">
                                    <div className="title">
                                        <h4>{val.title}:</h4>
                                    </div>
                                    <div className="title-value">
                                        <h6>{val.description}</h6>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                    <div className="package-price">
                        <h3>Starting Price</h3>
                        <h4>AED {packageData[0]?.price[0]?.price}</h4>
                    </div>
                    <div className="package-delete-edit-btn">
                        <button id="delete" onClick={() => {
                            DeletePackage();
                        }}>
                            <img src="delete1.svg" />
                            Delete
                        </button>
                        <button id="edit" onClick={() => {
                            setPackagePreview(true);
                        }}>
                            <img src="editicon.svg" />
                            Edit
                        </button>
                    </div>

                </>)}
            </div>
        </div>
        {packagePreview && <CreatePackageForm close={close}
            packageEditedId={packageData[0]._id}
            titleP={packageData[0].title} descriptionP={packageData[0].packageOverview}
            imageCoverP={packageData[0].thumbnailImage} imageBannerP={packageData[0].bannerImage}
            categoryP={packageData[0].categoryId} categoryNameP={packageData[0].categoryName}
            durationP={packageData[0].duration} durationNameP={packageData[0].duration}
            cancellationRefundPolicyP={packageData[0]?.cancellationRefundPolicy} 
            inclusionP={packageData[0].includedServices} exclusionP={packageData[0].excludedServices}
            dayWisePlanP={packageData[0].dayWisePlan}
            currencyP={"AED"}
            countryName={packageData[0].location.country} cityName={packageData[0].location.city}
            adultPriceP={packageData[0]?.price[0]?.price}
            childrenPriceP={packageData[0]?.price[1]?.price}
            loadPackagesList={loadPackagesList}
        />}
    </StyledTableContainer>

};

export default PackageDetails;
