import React, { useState, useEffect } from "react";
import "./TransferForm.css";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Select from 'react-select';
import { token, APIPath } from "../../config/api";
import AirportForm from "../Airport/AirportForm";
import HotelForm from "../hotels/HotelForm";
import AddZone from "../AddZone/AddZone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransferForm = ({ isOpen, onClose }) => {
  const Uppercase = (str) => str.toUpperCase();
  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [transferType, setTransferType] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleImage, setVehicleImage] = useState("");
  const [handbagAllowed, setHandbagAllowed] = useState("");
  const [maxPassenger, setMaxPassenger] = useState();
  const [suitcase, setSuitcase] = useState();
  const [currency, setCurrency] = useState("AED");
  const [price, setPrice] = useState();
  const [addinfo, setAddInfo] = useState("");

  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };
  const handleBackStep = () => {
    console.log("back Clicked");
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const Id = "65b20828d1cdfbe9749eb653";
  // -------------------------------Country------------------

  const handleCountryChange = (e) => {
    e.preventDefault();
    setCountryId(e.target.value);
    setCountryName(e.target.options[e.target.selectedIndex].text);
  }
  useEffect(() => {
    if (Id) {
      fetch(`${APIPath}/api/v1/agency/country?continent=${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setCountry(data.data);
        })
        .catch((err) => alert(err));
    }
  }, [Id]);
  // ------------------------------------City------------------------
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState("");

  useEffect(() => {
    if (countryId) {
      fetch(`${APIPath}/api/v1/agency/city?country=${countryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setCity(data.data);
        })
        .catch((err) => alert(err));
    }
  }, [countryId]);
  //-------------------------------------Airport----------------------
  const [airport, setAirport] = useState(null);
  const [airportF, setAirportF] = useState(null);
  const [airportFrom, setAirportFrom] = useState("");
  const [airportNameFrom, setAirportNameFrom] = useState('');
  const [airportTo, setAirportTo] = useState("");
  const [airportNameTo, setAirportNameTo] = useState("");

  const getFromAirport = () => {
    if (cityId) {
      fetch(`${APIPath}/api/v1/agency/airport?city=${cityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setAirportF(data.data);
        })
        .catch((err) => alert(err));
    }
  }
  const getToAirport = () => {
    if (countryId) {
      fetch(`${APIPath}/api/v1/agency/airport?country=${countryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          if (airportFrom) {
            const dropOffAirport = data.data.filter((item) => item._id !== airportFrom);
            setAirport(dropOffAirport);
          }
          else {
            setAirport(data.data);
          }

        })
        .catch((err) => alert(err));
    }
  }

  useEffect(() => {
    getFromAirport()
  }, [cityId]);

  useEffect(() => {
    getToAirport();
  }, [countryId, airportFrom])
  //---------------------------------------Hotel------------------------
  const [hotel, setHotel] = useState(null);
  const [hotelF, setFHotel] = useState(null);
  const [hotelFrom, setHotelFrom] = useState("");
  const [hotelNameFrom, setHotelNameFrom] = useState('');
  const [hotelTo, setHotelTo] = useState("");
  const [hotelNameTo, setHotelNameTo] = useState("");

  const getFromHotel = () => {
    if (cityId) {
      fetch(`${APIPath}/api/v1/agency/hotels?city=${cityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setFHotel(data.data);
        })
        .catch((err) => alert(err));
    }
  }

  const getHotel = () => {
    if (countryId) {
      fetch(`${APIPath}/api/v1/agency/hotels?country=${countryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          if (hotelFrom) {
            const pickupHotel = data.data.filter((item) => item._id !== hotelFrom)
            setHotel(pickupHotel);
          }
          else {
            setHotel(data.data);
          }
        })
        .catch((err) => alert(err));
    }
  }


  useEffect(() => {
    getFromHotel();
  }, [cityId]);

  useEffect(() => {
    getHotel();
  }, [countryId, hotelFrom]);

  // -----------------------------Type of Servicess-------------------------------
  const [typeofServices, settypeofServices] = useState("");
  const [typeofServicesName, settypeofServicesName] = useState('')

  //---------------------Zone-------------------------------
  const [zone, setZone] = useState(null);
  const [zoneF, setZoneF] = useState(null);
  const [zoneId, setZoneId] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [zoneFrom, setZoneFrom] = useState("");
  const [zoneNameFrom, setZoneNameFrom] = useState('');
  const [zoneTo, setZoneTo] = useState("");
  const [zoneNameTo, setZoneNameTo] = useState("");

  const getFromZone = () => {
    if (cityId) {
      fetch(`${APIPath}/api/v1/agency/zone?city=${cityId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        method: 'GET',
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((data) => {
          setZoneF(data.data)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
  const getZone = () => {
    if (countryId) {
      fetch(`${APIPath}/api/v1/agency/zone?country=${countryId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        method: 'GET',
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((data) => {
          if (zoneFrom) {
            const dropOffZone = data.data.filter((item) => item._id !== zoneFrom);
            setZone(dropOffZone)
          }
          else {
            setZone(data.data);
          }
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  useEffect(() => {
    getFromZone()
  }, [cityId])

  useEffect(() => {
    getZone();
  }, [countryId, zoneFrom])
  // ----------------------------------------Select Vehicle---------------------
  const [vehicle, setVehicle] = useState()
  useEffect(() => {
    if(transferType){
      fetch(`${APIPath}/api/v1/agency/vehicles?vehicleType=${transferType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setVehicle(data.data);
        })
        .catch((err) => alert(err));
    }

  }, [transferType])

  const options = vehicle?.map(val => ({
    value: val._id,
    label: (
      <div className="select-vehicle">
        <img src={val.images[0]} style={{ height: "20px", width: "20px" }} alt="vehicle" />
        {capitalize(val.name)}
        {/* {val.type} */}
        {/* {val.class} */}
      </div>
    )
  }));
  const [selectedvehicle, setSelectedVehicle] = useState(null);
  const getSelectedVehicle = (val) => {
    console.log(val.value);
    setSelectedVehicle(val.value)
  }
  const [vehicleProviderEmail, setvehicleProviderEmail] = useState('');
  const [vehicleProviderMobile, setvehicleProviderMobile] = useState('');
  const [vehicleProviderName, setvehicleProviderName] = useState('');
  const [vehicleProviderId, setvehicleProviderId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (selectedvehicle) {
      fetch(`${APIPath}/api/v1/agency/vehicles?id=${selectedvehicle}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setVehicleName(data.data[0].name);
          setMaxPassenger(data.data[0].maxPassenger)
          setSuitcase(data.data[0].suitCaseAllowed)
          setHandbagAllowed(data.data[0].handbagAllowed)
          setVehicleImage(data.data[0].images[0])
          setvehicleProviderEmail(data.data[0].vehicleProviderEmail)
          setvehicleProviderMobile(data.data[0].vehicleProviderMobile)
          setvehicleProviderName(data.data[0].vehicleProviderName)
          setvehicleProviderId(data.data[0].vehicleProviderId)
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [selectedvehicle])
  // -----------------------------API Call for add Transfer-------------------------------
  const ServicesType = (e) => {
    settypeofServices(e.target.value);
    settypeofServicesName(e.target.options[e.target.selectedIndex].text)
  };

  const transferDetails = {
    continent: Id,
    country: countryId,
    city: cityId,
    type: typeofServices,
    transferType: transferType,
    ...(typeofServices === 'AirportToHotel' && { airportFrom: airportFrom, hotelTo: hotelTo, dropoffZone: zoneTo }),
    ...(typeofServices === 'HotelToAirport' && { hotelFrom: hotelFrom, pickupZone: zoneFrom, airportTo: airportTo }),
    ...(typeofServices === 'AirportToAirport' && { airportFrom: airportFrom, airportTo: airportTo }),
    ...(typeofServices === 'HotelToHotel' && { hotelFrom: hotelFrom, pickupZone: zoneFrom, hotelTo: hotelTo, dropoffZone: zoneTo }),
    cost: parseInt(price),
    vehicle: {
      name: vehicleName,
      suitCaseAllowed: parseInt(suitcase),
      handbagAllowed: parseInt(handbagAllowed),
      images: [vehicleImage],
      maxPassenger: parseInt(maxPassenger),
      vehicleProviderEmail: vehicleProviderEmail,
      vehicleProviderId: vehicleProviderId,
      vehicleProviderMobile: vehicleProviderMobile,
      vehicleProviderName: vehicleProviderName,
    },
    additionalDetails: addinfo,
  };

  const submitTransferDetails = () => {
    setIsLoading(true);
    // if (
    //   transferDetails.country &&
    //   transferDetails.city &&
    //   transferDetails.type &&
    //   transferDetails.transferType &&
    //   (
    //     (typeofServices === 'AirportToAirport' && (airportFrom && airportTo)) ||
    //     (typeofServices === 'AirportToHotel' && (airportFrom && hotelTo)) ||
    //     (typeofServices === 'HotelToAirport' && (hotelFrom && airportTo)) ||
    //     (typeofServices === 'HotelToHotel' && (hotelFrom && hotelTo))
    //   ) &&
    //   transferDetails.cost &&
    //   transferDetails.vehicle.name &&
    //   transferDetails.additionalDetails
    // ) 
    // {
    fetch(`${APIPath}/api/v1/agency/transfers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      mode: "cors",
      body: JSON.stringify(transferDetails),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        alert(data.message);
        console.log(data);
        onClose();
      })
      .catch((err) => {
        alert(err);
        setIsLoading(false);
      });
    // }
    // else {
    //   console.log(transferDetails);
    //   alert("Please fill all details.");
    //   setIsLoading(false)
    // }
  };
  const [hotelOrZone, setHotelOrZone] = useState("zone");
  const [hotelOrZoneTo, setHotelOrZoneTo] = useState("zone");
  const [showAirportForm, setShowAirportForm] = useState(false);
  const [showHotelForm, setShowHotelForm] = useState(false);
  const [showZoneForm, setShowZoneForm] = useState(false);

  const closeForm = () => {
    setShowAirportForm(false);
    setShowHotelForm(false);
    setShowZoneForm(false);
    getHotel();
    getFromHotel();
    getZone();
    getFromZone();
    getFromAirport();
    getToAirport();
  }


  return (

    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleNextStep();
        }}>
          <div className="transfer-form">
            {currentStep === 1 && (
              <div id="step1">
                {/* -------------------------------Step1------------------------------------*/}
                <h2>Region Details</h2>
                <br />
                <div className="transfer-form-country-city-type">
                  <div className="transfer-form-country-parent">
                    <p>Country</p>
                    <div className="transfer-form-country">
                      <select required
                        value={countryId}
                        onChange={handleCountryChange}>
                        <option value="" disabled >Select Country</option>
                        {country?.map((val) => {
                          return (
                            <>
                              <option value={val._id} name={val.name}>{Uppercase(val.name)}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="transfer-form-country-parent">
                    <p>City</p>
                    <div className="transfer-form-country">
                      <select required value={cityId}
                        onChange={(e) => {
                          setCityId(e.target.value);
                          setCityName(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                      >
                        <option value="" disabled >Select City</option>
                        {city?.map((val, id) => {
                          return (
                            <option value={val._id} name={val.name}>
                              {capitalize(val.name)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="transfer-form-country-parent">
                    <p>Type</p>
                    <div className="transfer-form-country">
                      <select required onChange={ServicesType} value={typeofServices}>
                        <option value="" disabled>Select Service Type</option>
                        <option value="AirportToHotel">Airport To Hotel</option>
                        <option value="HotelToAirport">Hotel To Airport</option>
                        <option value="AirportToAirport">Airport To AirPort</option>
                        <option value="HotelToHotel">Hotel To Hotel</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="pick-up-drop-up-duration-parent">
                  {typeofServices === "AirportToHotel" && (
                    <>
                      <div className="pick-up-drop-up-duration">
                        <div className="pick-up-parent">
                          <p>Pick Up</p>
                          <div className="pick-up">
                            <select required
                              onChange={(e) => {
                                setAirportFrom(e.target.value);
                                if (e.target.value === "other") {
                                  setShowAirportForm(true)
                                }
                                setAirportNameFrom(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }}
                              value={airportFrom}
                            >
                              <option value="" disabled> Select Airport </option>
                              {airportF?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{Uppercase(val.name)}</option>
                                  </>
                                );
                              })}
                              <option value="other" >Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="pick-up-parent">
                          <p>Drop Off</p>
                          <div style={{ display: "flex", gap: "2rem" }}>
                            <div>
                              <input type="checkbox"
                                checked={hotelOrZone === "zone" ? true : false}
                                onClick={() => {
                                  setHotelOrZone("zone")
                                }}
                              />&nbsp;
                              <label>Zone</label>
                            </div>
                            <div>
                              <input type="checkbox"
                                checked={hotelOrZone === "hotel" ? true : false}
                                onClick={() => {
                                  setHotelOrZone("hotel")
                                }}
                              />&nbsp;
                              <label>Hotel</label>
                            </div>
                          </div>
                          <div className="pick-up">
                            <select
                              required
                              value={hotelOrZone === "hotel" ? hotelTo : zoneTo}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                if (hotelOrZone === "hotel") {
                                  setHotelTo(selectedValue);
                                  setZoneTo("");
                                  setHotelNameTo(selectedText);
                                  if (selectedValue === "other") {
                                    setShowHotelForm(true);
                                  }
                                }
                                if (hotelOrZone === "zone") {
                                  setZoneId(selectedValue);
                                  setZoneTo(selectedValue);
                                  setHotelTo("");
                                  setZoneNameTo(selectedText);
                                  if (selectedValue === "other") {
                                    setShowZoneForm(true);
                                  }
                                }
                              }}
                            >
                              <option value="" disabled>
                                {hotelOrZone === "hotel" ? "Select Hotel" : "Select Zone"}
                              </option>
                              {hotelOrZone === "hotel" && hotel?.map((val, id) => (
                                <option key={id} value={val._id}>{val.name}</option>
                              ))}
                              {hotelOrZone === "zone" && zone?.map((val, id) => (
                                <option key={id} value={val._id}>{val.name}</option>
                              ))}
                              <option value="other">Other</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </>

                  )}
                  {typeofServices === "HotelToAirport" && (
                    <div className="pick-up-drop-up-duration">
                      <div className="pick-up-parent">
                        <p>Pick Up</p>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZone === "zone" ? true : false}
                              onClick={() => {
                                setHotelOrZone("zone")
                              }}
                            />&nbsp;
                            <label>Zone</label>
                          </div>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZone === "hotel" ? true : false}
                              onClick={() => {
                                setHotelOrZone("hotel")
                              }}
                            />&nbsp;
                            <label>Hotel</label>
                          </div>
                        </div>
                        <div className="pick-up">
                          <select required
                            value={hotelOrZone === "hotel" ? hotelFrom : zoneFrom}
                            onChange={(e) => {
                              if (hotelOrZone === "hotel") {
                                setHotelFrom(e.target.value);
                                setZoneFrom("");
                                setHotelNameFrom(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZone === "zone") {
                                setZoneFrom(e.target.value);
                                setHotelFrom("");
                                setZoneNameFrom(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZone === "hotel" && e.target.value === "other") {
                                setShowHotelForm(true)
                              }
                              if (hotelOrZone === "zone" && e.target.value === "other") {
                                setShowZoneForm(true)
                              }
                            }}
                          >
                            <option value="" disabled>{hotelOrZone === "hotel" ? "Select Hotel" : "Select Zone"}</option>

                            {hotelOrZone === "hotel" && (
                              hotelF?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            {hotelOrZone === "zone" && (
                              zoneF?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="pick-up-parent">
                        <p>Drop Off</p>
                        <div className="pick-up">
                          <select required value={airportTo}
                            onChange={(e) => {
                              setAirportTo(e.target.value);
                              if (e.target.value === "other") {
                                setShowAirportForm(true)
                              }
                              setAirportNameTo(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                          >
                            <option value="" disabled>
                              Select Airport
                            </option>
                            {airport?.map((val, id) => {
                              return (
                                <>
                                  <option value={val._id}>{Uppercase(val.name)}</option>
                                </>
                              );
                            })}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {typeofServices === "AirportToAirport" && (
                    <div className="pick-up-drop-up-duration">
                      <div className="pick-up-parent">
                        <p>Pick Up</p>
                        <div className="pick-up">
                          <select required value={airportFrom}
                            onChange={(e) => {
                              setAirportFrom(e.target.value);
                              if (e.target.value === "other") {
                                setShowAirportForm(true)
                              }
                              setAirportNameFrom(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                          >
                            <option value="" disabled>Select Airport</option>
                            {airportF?.map((val, id) => {
                              return (
                                <>
                                  <option value={val._id}>{Uppercase(val.name)}</option>
                                </>
                              );
                            })}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="pick-up-parent">
                        <p>Drop Off</p>
                        <div className="pick-up">
                          <select required value={airportTo}
                            onChange={(e) => {
                              setAirportTo(e.target.value);
                              if (e.target.value === "other") {
                                setShowAirportForm(true)
                              }
                              setAirportNameTo(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                          >
                            <option value="" disabled> Select Airport</option>
                            {airport?.map((val, id) => {
                              return (
                                <>
                                  <option value={val._id}>{Uppercase(val.name)}</option>
                                </>
                              );
                            })}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {typeofServices === "HotelToHotel" && (
                    <div className="pick-up-drop-up-duration">
                      <div className="pick-up-parent">
                        <p>Pick Up</p>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZone === "zone" ? true : false}
                              onClick={() => {
                                setHotelOrZone("zone")
                              }}
                            />&nbsp;
                            <label>Zone</label>
                          </div>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZone === "hotel" ? true : false}
                              onClick={() => {
                                setHotelOrZone("hotel")
                              }}
                            />&nbsp;
                            <label>Hotel</label>
                          </div>
                        </div>
                        <div className="pick-up">
                          <select required
                            value={hotelOrZone === "hotel" ? hotelFrom : zoneFrom}
                            onChange={(e) => {
                              if (hotelOrZone === "hotel") {
                                setHotelFrom(e.target.value);
                                setZoneFrom("")
                                setHotelNameFrom(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZone === "zone") {
                                setZoneFrom(e.target.value);
                                setHotelFrom("");
                                setZoneNameFrom(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZone === "hotel" && e.target.value === "other") {
                                setShowHotelForm(true)
                              }
                              if (hotelOrZone === "zone" && e.target.value === "other") {
                                setShowZoneForm(true)
                              }
                            }}
                          >
                            <option value="" disabled>{hotelOrZone === "hotel" ? "Select Hotel" : "Select Zone"}</option>

                            {hotelOrZone === "hotel" && (
                              hotelF?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            {hotelOrZone === "zone" && (
                              zoneF?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="pick-up-parent">
                        <p>Drop Off</p>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZoneTo === "zone" ? true : false}
                              onClick={() => {
                                setHotelOrZoneTo("zone")
                              }}
                            />&nbsp;
                            <label>Zone</label>
                          </div>
                          <div>
                            <input type="checkbox"
                              checked={hotelOrZoneTo === "hotel" ? true : false}
                              onClick={() => {
                                setHotelOrZoneTo("hotel")
                              }}
                            />&nbsp;
                            <label>Hotel</label>
                          </div>
                        </div>
                        <div className="pick-up">
                          <select required
                            value={hotelOrZoneTo === "hotel" ? hotelTo : zoneTo}
                            onChange={(e) => {
                              if (hotelOrZoneTo === "hotel") {
                                setHotelTo(e.target.value);
                                setZoneTo("");
                                setHotelNameTo(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZoneTo === "zone") {
                                setZoneId(e.target.value);
                                setZoneTo(e.target.value);
                                setHotelTo("");
                                setZoneNameTo(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }
                              if (hotelOrZoneTo === "hotel" && e.target.value === "other") {
                                setShowHotelForm(true)
                              }
                              if (hotelOrZoneTo === "zone" && e.target.value === "other") {
                                setShowZoneForm(true)
                              }
                              // setHotelNameTo(
                              //   e.target.options[e.target.selectedIndex].text
                              // );
                            }}
                          >
                            <option value="" disabled>{hotelOrZoneTo === "hotel" ? "Select Hotel" : "Select Zone"}</option>
                            {hotelOrZoneTo === "hotel" && (
                              hotel?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            {hotelOrZoneTo === "zone" && (
                              zone?.map((val, id) => {
                                return (
                                  <>
                                    <option value={val._id}>{val.name}</option>
                                  </>
                                );
                              })
                            )}
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div id="step2">
                <h2>Vehicle Details</h2>
                <br />
                {/* -------------------------------Step2-------------------------------------------- */}
                <div className="transfer-form-country-city-type">
                  <div className="transfer-form-country-parent">
                    <p>Transfer Type</p>
                    <div className="transfer-form-country">
                      <select required value={transferType}
                        onChange={(e) => {
                          setTransferType(e.target.value);
                        }}
                      >
                        <option value="" disabled>Select Transfer Type</option>
                        <option value="Private">Private</option>
                        <option value="Shared">Shared</option>
                      </select>
                    </div>
                  </div>
                  <div className="transfer-form-country-parent">
                    <p>Select Vehicle</p>
                    <div className="transfer-form-country">
                      <Select required options={options}
                        onChange={getSelectedVehicle}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            cursor: 'pointer',
                            background: 'rgba(192, 192, 192, -0.8)',
                            border: 'none !important',
                            outline: 'none',
                            boxShadow: 'none !important',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="extra-details-of-transfer">
                  <div className="extra-price-addtional-details">
                    <div className="price-of-transfer">
                      <p>Set Price</p>
                      <div className="price-currency">
                        <select required id="select" value={currency}
                          onChange={(e) => {
                            setCurrency(e.target.value);
                          }}
                        >
                          {/* <option value="" disabled>Select Currency</option> */}
                          {/* <option value="USD">USD</option> */}
                          <option value="AED">AED</option>
                          {/* <option value="INR">INR</option> */}
                        </select>
                        &nbsp;&nbsp;&nbsp;
                        <input required
                          type="number"
                          value={price}
                          min={50}
                          max={50000}
                          onChange={(e) => {
                            setPrice(parseInt(e.target.value));
                          }}
                          placeholder="1200"
                        />
                      </div>
                    </div>
                    <div className="additional-details">
                      <p>Additional Info.</p>
                      <textarea
                        placeholder="Very Luxurious and Comfortable"
                        value={addinfo}
                        onChange={(e) => {
                          setAddInfo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div id="step3">
                {/* ------------------------------Step3------------------------------------------------------ */}
                <div className="region-details">
                  <h2>Region Info.</h2>
                  <div className="country-city-type">
                    <h4>
                      Country:&nbsp;&nbsp; <span>{countryName}</span>
                    </h4>
                    <h4>
                      City:&nbsp;&nbsp; <span>{cityName}</span>
                    </h4>
                    <h4>
                      Type:&nbsp;&nbsp; <span>{typeofServices}</span>
                    </h4>
                  </div>
                  <div className="country-city-type">
                    <h4>
                      Pick Up:&nbsp;&nbsp;
                      <span>
                        {(typeofServices === 'HotelToAirport' && (hotelNameFrom || zoneNameFrom))}
                        {(typeofServices === 'HotelToHotel' && (hotelNameFrom || zoneNameFrom))}
                        {(typeofServices === 'AirportToHotel' && airportNameFrom)}
                        {(typeofServices === 'AirportToAirport' && airportNameFrom)}
                      </span>
                    </h4>
                    <h4>
                      Drop Off:&nbsp;&nbsp;
                      <span>
                        {(typeofServices === 'HotelToAirport' && airportNameTo)}
                        {(typeofServices === 'HotelToHotel' && (hotelNameTo || zoneNameTo))}
                        {(typeofServices === 'AirportToHotel' && (hotelNameTo || zoneNameTo))}
                        {(typeofServices === 'AirportToAirport' && airportNameTo)}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="region-details">
                  <br />
                  <h2>Vehicle Info.</h2>
                  <div className="country-city-type">
                    <h4>
                      Maximum Passenger:&nbsp;&nbsp; <span>{maxPassenger}</span>{" "}
                    </h4>

                  </div>
                  <div className="country-city-type">
                    <h4>
                      Price:&nbsp;&nbsp; <span>{currency}&nbsp;{price}</span>{" "}
                    </h4>
                    <h4>
                      Maximum Suitcase allowed:&nbsp;&nbsp; <span>{suitcase}</span>{" "}
                    </h4>
                    <h4>
                      Maximum Handbag allowed:&nbsp;&nbsp; <span>{handbagAllowed}</span>{" "}
                    </h4>
                  </div>
                  <div className="country-city-type">
                    <h4>
                      Additional Info:&nbsp;&nbsp; <span>{addinfo}</span>{" "}
                    </h4>
                  </div>
                </div>
              </div>
            )}
            {/* -------------------------Button----------------------------------------------------------- */}
            <div className="transfer-form-back-next-btn">
              <div className="back-btn">
                {currentStep > 1 && <button onClick={handleBackStep}>Back</button>}
              </div>
              <div className="next-btn">
                {isLoading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    {currentStep === 3 ? (
                      <button type="submit" onClick={submitTransferDetails}>Submit</button>
                    ) : (
                      <button
                      // onClick={handleNextStep}
                      >Next</button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </List>
      {showAirportForm && <AirportForm
        onClose={closeForm}
        countryIdT={countryId}
      // countryNameT={countryName}
      // cityIdT={cityId}
      // cityNameT={cityName}
      />}
      {showZoneForm && <AddZone
        onClose={closeForm}
        countryIdT={countryId}
      // countryNameT={countryName}
      // cityIdT={cityId}
      // cityNameT={cityName}
      />}
      {showHotelForm && <HotelForm
        onClose={closeForm}
        countryIdT={countryId}
      // countryNameT={countryName}
      // cityIdT={cityId}
      // cityNameT={cityName}
      // zoneIdT={zoneId}
      // zoneNameT={zoneName}
      />}
    </Dialog>
  );
};

export default TransferForm;
