import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import BookAttractionDetails from "./BookAttractionDetails";
import { token, APIPath } from "../../../config/api";
import './Attraction.css';
import { styled } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import {
    Stack,
    Pagination,
} from "@mui/material";

const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: " 2rem auto 0 auto",
    zIndex: 1,
    background: "none",
});

const AttractionList = ({ searchText, bookingStatus }) => {
    document.body.style.overflow = 'auto';
    const [attractionList, setAttractionList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState(false);
    const [bookAttractionId, setBookAttractionId] = useState('');
    // const [attractionData, setAttractionData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);

    const packagelist = () => {
        fetch(`${APIPath}/api/v1/agency/bookings/attractions`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                if (bookingStatus === "Pending") {
                    const pendingdata = data.data.filter((data) => data.bookingStatus === "Pending")
                    setAttractionList(pendingdata);
                    setFilterdata(pendingdata);
                }
                else if (bookingStatus === "Awaiting Payment") {
                    const waitingData = data.data.filter(item => item.bookingStatus === "Awaiting Payment");
                    setAttractionList(waitingData);
                    setFilterdata(waitingData)
                }
                else if (bookingStatus === "On Hold") {
                    const holdData = data.data.filter(item => item.bookingStatus === "On Hold");
                    setAttractionList(holdData);
                    setFilterdata(holdData)
                }
                else if (bookingStatus === "Confirmed") {
                    const confirmData = data.data.filter(item => item.bookingStatus === "Confirmed");
                    setAttractionList(confirmData);
                    setFilterdata(confirmData)
                }
                else if (bookingStatus === "Rejected") {
                    const rejectedData = data.data.filter(item => item.bookingStatus === "Rejected");
                    setAttractionList(rejectedData);
                    setFilterdata(rejectedData)
                }
                else {
                    setAttractionList(data.data);
                    setFilterdata(data.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching vehicle list:', error);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        packagelist();
    }, [bookingStatus]);

    // const filterdata =attractionList && attractionList?.filter(item =>
    //     item.bookingID.toLowerCase().includes(searchText.toLowerCase())
    //   );

    const filterdata = attractionList && attractionList?.filter(item =>
        item.bookingID?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.agentEmail?.toLowerCase().includes(searchText.toLowerCase())
    );
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = filterdata && filterdata.slice(indexOfFirstItem, indexOfLastItem);


    // const filteredLandCombo = attractionData.filter(item =>
    //     item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
    // );

    const seeBooking = (id) => {
        setBookingDetails(true);
        setBookAttractionId(id)
    }
    const closeBooking = () => {
        setBookingDetails(false);
        packagelist();
    }
    const [filterdata1, setFilterdata] = React.useState([]);
    const handleDownload = () => {
        const BookingList = filterdata1.map(val => [
            val.bookingID,
            val.agentName,
            val.agentEmail,
            val.createdAt.split("T")[0],
            val.totalCost,
            val.bookingStatus
        ]);
        const headers = [
            "Booking Id",
            "Agent Name",
            "Agent Email",
            "Booking Date",
            "Total Cost",
            "Booking Status"
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers, ...BookingList]);
        XLSX.utils.book_append_sheet(wb, ws, 'Booking List');
        XLSX.writeFile(wb, 'Magical_Vacation_Booking_list.xlsx');

    }

    return <>
        {isLoading ? (
            <div className="loader"></div>) : (
            <>
                {currentItems?.length > 0 ? (
                    <>
                        <button id="download-btn" onClick={handleDownload}><img src="downloadicon.svg" />&nbsp;Download</button>
                        <div className="package-list-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Booking Id</th>
                                        <th>Agent Name</th>
                                        <th>Agent Email</th>
                                        <th>Booking Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems?.map((val, id) => {
                                        return <>
                                            <tr>
                                                <td>{val.bookingID}</td>
                                                <td>{val.agentName}</td>
                                                <td>{val.agentEmail}</td>
                                                <td>{val.createdAt.split("T")[0]}</td>
                                                <td>{val.totalCost}</td>
                                                <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                                                    (val.bookingStatus === "On Hold" && "on-hold") ||
                                                    (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                                                    (val.bookingStatus === "Confirmed" && "confirm") ||
                                                    (val.bookingStatus === "Rejected" && "rejected")
                                                }>{val.bookingStatus}</p></td>
                                                <td>
                                                    <button onClick={() => {
                                                        seeBooking(val._id)
                                                    }}>
                                                        <MdOutlineRemoveRedEye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <div className="not-found-booking">
                        <h2>No Record Found</h2>
                    </div>
                )}
            </>
        )}
        {bookingDetails && <BookAttractionDetails onClose={closeBooking} bookingId={bookAttractionId} />}
        <StyledPaginationContainer>
            <Stack spacing={1}>
                <Pagination
                    count={Math.ceil((attractionList && attractionList.length) / perPage)}
                    // count={1}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    variant="outlined"
                    color="primary"
                    boundaryCount={1}
                />
            </Stack>
        </StyledPaginationContainer>
    </>

}
export default AttractionList;