import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import PackageDetail from "./LandCombosPreview";
import { token, APIPath } from "../../config/api";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  width: open ? "80%" : "94%",
  transition: "width 0.3s ease",
  paddingTop: "7px",
  marginLeft: open ? "243px" : "69px",
  position: "relative",
  borderRadius: "0px",
  overflow: "hidden",
  marginBottom: "20px",
  boxShadow: "none",
}));

const CreatePackageForm = ({ open, close, loadPackagesList,
  tilteP, descriptionP, bannerImageP, coverImageP, exceptions,cancellationRefundPolicyP,
  inclusionP, exclusionP, adultsPriceP, childpriceP, editedLandCombosId }) => {

  document.body.style.overflow = 'hidden';
  const [title, setTitle] = React.useState(tilteP || '');
  const [description, setDescription] = React.useState(descriptionP || '');
  const [imageCover, setImageCover] = React.useState('');
  const [coverPreview, setCoverPreview] = React.useState(coverImageP || '')
  const [imageBanner, setImageBanner] = React.useState('');
  const [bannerPreview, setBannerPreview] = React.useState(bannerImageP || '');
  const [country, setCountry] = React.useState(null)
  const [fromCountry, setFromCountry] = React.useState("");
  const [fromCountryName, setFromCountryName] = React.useState('');
  const [city, setCity] = React.useState(null)
  const [cityName, setCityName] = React.useState("")
  const [selectCity, setSelectCity] = React.useState("")
  // const [place, setPlace] = React.useState('');
  const [inclusions, setInclusions] = React.useState(inclusionP || ['']);
  const [exclusions, setExclusions] = React.useState(exclusionP || ['']);
  const [adultsPrice, setAdultsPrice] = React.useState(adultsPriceP || '');
  const [childPrice, setChildPrice] = React.useState(childpriceP || '')
  const [currency, setCurrency] = React.useState("AED");
  const [cancellationPolicy,setCancellationPolicy]=React.useState(cancellationRefundPolicyP||'');

  const [expectation, setExpectation] = React.useState(exceptions || [
    { location: '', description: '', 
      // time: '' 
    }
  ]);
  const handleLocationChange = (id, value) => {
    const updatedExpectation = expectation.map((item, index) =>
      index === id ? { ...item, location: value } : item
    );
    setExpectation(updatedExpectation);
  };

  const handleLdescriptionChange = (id, value) => {
    const updatedExpectation = expectation.map((item, index) =>
      index === id ? { ...item, description: value } : item
    );
    setExpectation(updatedExpectation);
  };

  // const handleOpenTimeChange = (id, value) => {
  //   const updatedExpectation = expectation.map((item, index) =>
  //     index === id ? { ...item, time: value } : item
  //   );
  //   setExpectation(updatedExpectation);
  // };
  const handleAddException = () => {
    setExpectation([...expectation, ''])
  }
  const Id = "65b20828d1cdfbe9749eb653";
  React.useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/country?continent=${Id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setCountry(data.data);
      })
      .catch((err) => alert(err));

  }, [Id])

  React.useEffect(() => {
    if (fromCountry) {
      fetch(`${APIPath}/api/v1/agency/city?country=${fromCountry}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.data)
          setCity(data.data);
        })
        .catch((err) => alert(err));
    }
  }, [fromCountry])

  const handleFromCountryChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setFromCountry(e.target.value);
    setFromCountryName(selectedCategoryName);
  };
  const handlePlaceChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setSelectCity(e.target.value);
    setCityName(selectedCategoryName);
  };
  const handleImageChangeCover = (e) => {
    const file = e.target.files[0];
    setImageCover(file);
    setCoverPreview(URL.createObjectURL(file));
  };

  const handleImageChangeBanner = (e) => {
    const file = e.target.files[0];
    setImageBanner(file);
    setBannerPreview(URL.createObjectURL(file))
  };
  const handleAddInclusion = () => {
    setInclusions([...inclusions, '']);
  };

  const handleAddExclusion = () => {
    setExclusions([...exclusions, '']);
  };

  const handleInclusionChange = (index, event) => {
    const newInclusions = [...inclusions];
    newInclusions[index] = event.target.value;
    setInclusions(newInclusions);
  };

  const handleExclusionChange = (index, event) => {
    const newExclusions = [...exclusions];
    newExclusions[index] = event.target.value;
    setExclusions(newExclusions);
  };

  //  ------------------------------------Data structure that send to API-----------------

  const [previewPage, setPreviewPage] = React.useState(false);
  const previewPageOpen = () => {
    setPreviewPage(true);
    // console.log(cancellationPolicy.split('\n'));
  }
  const previewPageClose = () => {
    setPreviewPage(false);
    close()
  }
  const Uppercase = (str) => str?.toUpperCase();
  const capitalize = (str) => {
      if (typeof str !== 'string') return '';
      return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
    };
  return (
    <StyledTableContainer component={Paper} open={open}>
      <div className="admin-package-detail-container">
        <div className="admin-package-detail-page"
          style={{ background: "rgba(249, 249, 249, 1)" }}
        >
          <div className="back-and-text">
            <div className="back">
              <button onClick={close} style={{ background: "none", border: "none", cursor: "pointer" }}>
                <img src="backicon.svg" alt="back-icon" />
              </button>            </div>
            <div className="text">
              <h2>Add New LandCombo</h2>
            </div>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            previewPageOpen();
          }}>
            <div className="package-basic-details">
              <h3>Basic Details</h3>
              <div className="package-form-title-description">
                <div className="package-form-title">
                  <label>Title *</label>
                  <input type="text" placeholder="magical dubai" maxLength={60} value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="package-form-description">
                  <label>Description *</label>
                  <textarea type="text" placeholder="Very luxurious and comfortable" value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    required style={{ height: "100px", overflow: "hidden" }}
                  />
                </div>
              </div>
            </div>
            <div className="package-form-cover-banner-img">
              <div className="package-form-cover-img">
                <label>Add Cover Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeCover}
                  required={!coverPreview}
                />
                {coverPreview && <div className="package-form-img">
                  <img src={coverPreview} alt="coverPreview" />
                </div>
                }
              </div>
              <div className="package-form-banner-img">
                <label>Add Banner Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeBanner}
                  required={!bannerPreview}
                />
                {bannerPreview && <div className="package-form-img">
                  <img src={bannerPreview} alt="bannerPreview" />
                </div>
                }
              </div>
            </div>
            <div className="package-form-location-details">
              <h2>Location Details</h2>
              <div className="package-form-country-place">
                <div className="package-form-from-country">
                  <label>Country</label>
                  <select value={fromCountry} onChange={handleFromCountryChange} required>
                    <option value="" disabled>Select Country</option>
                    {country?.map((val, id) => {
                      return <option key={val._id} value={val._id}>{Uppercase(val.name)}</option>
                    })}
                  </select>
                </div>
                <div className="package-form-from-country">
                  <label>City</label>
                  <select value={selectCity} onChange={handlePlaceChange} required>
                    <option value="" disabled>Select city</option>
                    {city?.map((val, id) => {
                      return <option key={val._id} value={val._id}>{capitalize(val.name)}</option>
                    })}
                  </select>
                </div>
                {/* <div className="package-form-from-country">
                  <label>Places Included</label>
                  <input type="text" placeholder="Enter place..." value={place} required
                    onChange={(e) => {
                      setPlace(e.target.value)
                    }} />
                </div> */}
              </div>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Cancellation Policy</h2>
              <div className="package-form-cancellation-polocy">
                <textarea 
                value={cancellationPolicy?.length > 0 ? cancellationPolicy?.join("\n") :cancellationPolicy}
                placeholder="Write Cancellation Policy"
                 onChange={(e)=>{
                    // setCancellationPolicy(e.target.value);
                    setCancellationPolicy(e.target.value.split("\n"));
                 }}
                />
              </div>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Inclusion/Exclusions</h2>
              <div className="package-form-inclusion-exclusion-container">
                <div className="package-form-inclusion">
                  <label>Inclusions *</label>
                  {inclusions.map((inclusion, index) => (
                    <input
                      key={index}
                      type="text"
                      value={inclusion}
                      onChange={(event) => handleInclusionChange(index, event)}
                      required
                    />
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddInclusion}
                  ><span>+</span> Add more</p>
                </div>
                <div className="package-form-inclusion">
                  <label>Exclusions *</label>
                  {exclusions.map((exclusion, index) => (
                    <input
                      key={index}
                      type="text"
                      value={exclusion}
                      onChange={(event) => handleExclusionChange(index, event)}
                      required
                    />
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddExclusion}
                  ><span>+</span> Add more</p>
                </div>
              </div>
            </div>
            <div className="package-form-location-details">
              <h2>Meeting Point</h2>
              {expectation.map((val, id) => (
                <div key={id} className="package-form-country-place">
                  <div className="package-form-from-country">
                    <label>Location</label>
                    <input
                      type="text"
                      placeholder="Dubai..."
                      value={val.location}
                      onChange={(e) => handleLocationChange(id, e.target.value)}
                      required
                    />
                  </div>
                  <div className="package-form-from-country">
                    <label>Description</label>
                    <textarea
                      type="text"
                      placeholder="we are testing inside dubai city tour"
                      style={{ height: "100px", overflow: "hidden" }}
                      value={val.description}
                      onChange={(e) => handleLdescriptionChange(id, e.target.value)}
                    />
                  </div>
                  {/* <div className="package-form-from-country">
                    <label>Open Time</label>
                    <input
                      type="time"
                      value={val.time}
                      onChange={(e) => handleOpenTimeChange(id, e.target.value)}
                      style={{ width: "fit-content" }}
                      required
                    />
                  </div> */}
                </div>
              ))}
              <p style={{ cursor: "pointer", width: "fit-content" }}
                onClick={handleAddException}
              ><span style={{ color: "#8BC4FF", fontSize: "24px" }}>+</span> Add More</p>
            </div>
            <div className="package-form-price">
              <h2>Pricing</h2>
              <div style={{ display: "flex", gap: "4rem" }}>
                <div>
                  <label>Adults Price</label>
                  <div className="package-form-price-value">
                    <select value={currency} onChange={(e) => {
                      setCurrency(e.target.value)
                    }} required
                      style={{ width: "100px" }}
                    >
                      {/* <option value="" disabled>Currency</option> */}
                      <option value="AED">AED</option>
                      {/* <option value="INR">INR</option>
                    <option value="USD">USD</option> */}
                    </select>
                    <input type="number" placeholder="1200" value={adultsPrice} onChange={(e) => {
                      setAdultsPrice(e.target.value);
                    }} required />
                  </div>
                </div>
                <div>
                  <label>Children Price</label>
                  <div className="package-form-price-value">
                    <select value={currency} onChange={(e) => {
                      setCurrency(e.target.value)
                    }} required
                      style={{ width: "100px" }}
                    >
                      {/* <option value="" disabled>Currency</option> */}
                      <option value="AED">AED</option>
                    </select>
                    <input type="number" placeholder="1200" value={childPrice} onChange={(e) => {
                      setChildPrice(e.target.value);
                    }} required />
                  </div>
                </div>
              </div>
            </div>
            <div className="package-form-back-next-btn">
              <button id="back" onClick={close}>Back</button>
              <button id="next">Next</button>
            </div>
          </form>
        </div>
      </div>
      {previewPage && <PackageDetail close={previewPageClose} closeform={close}
        editedLandCombosId={editedLandCombosId}
        title={title} description={description}
        coverImage={coverPreview} bannerImage={bannerPreview} imageCover={imageCover} imageBanner={imageBanner}
        imageCoverP={coverPreview} imageBannerP={bannerPreview}
        fromCountry={fromCountry} fromCountryName={fromCountryName} 
        city={selectCity} cityName={cityName} 
        // place={place}
        inclusion={inclusions} exclusion={exclusions}
        currency={currency} childPrice={childPrice} adultsPrice={adultsPrice} loadPackagesList={loadPackagesList}
        expectation={expectation} cancellationPolicy={cancellationPolicy}
      />}
    </StyledTableContainer >
  );
};

export default CreatePackageForm;




