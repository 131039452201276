import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./TransferBookingDetail.css";

import { updateBookingStatus } from "../../services/bookingService";
import { APIPath, token } from "../../config/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TransferBookingDetail({ isOpen, onClose, id, }) {
  const [reasonCancel, setReasonCancel] = useState(false);
  const [raesonReject, setReasonReject] = useState(false);
  const [reasonPutHold, setReasonPutHold] = useState(false);
  const [reasonPaymentLink, setReasonPaymentLink] = useState(false);
  const [reasonMessage, setReasonMessage] = useState("");
  const [transferData, setTransferData] = useState();
  const [transfer, setTransfer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setshowModal1] = useState(true)
  const [gatewayType, setGatewayType] = useState("");
  const [bookingStatus, setBookingStatus] = useState('');

  useEffect(() => {
    fetch(
      `${APIPath}/api/v1/agency/bookings/transfers?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTransferData(data.data);
        setTransfer(data.data[0].transfers)
        setBookingStatus(data.data[0].bookingStatus)
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const checkMessage = () => {
    setReasonMessage("");
  };
  const [loading, setLoading] = useState(false);
  const [count, SetResendCount] = useState(0);

  const sendPaymentLink = () => {
    if (bookingStatus === 'Confirmed') {
      alert("Booking already Confirmed")
      return;
    }
    if(!gatewayType){
      alert("please select payment gateway type!");
      return;
    }
    if (count >= 3) {
      alert("Payment link already sent 3 times. Please check your email or contact support for assistance.");
      return;
    }
    setLoading(true);
    fetch(
      `${APIPath}/api/v1/agency/payments/transfer/payment-link?id=${id}&gatewayType=${gatewayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        alert(data.message);
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  const updateStatus = async (val, reasonMessage) => {
    let data = {};

    if (val === "On Hold" || val === "Cancelled" || val === "Rejected") {
      data["status"] = val;
      data["reason"] = reasonMessage;
    }
    if(data["reason"].length > 5){
      try {
        const datas = await updateBookingStatus(data, id);
      } catch (error) {
        alert("Error while calling update API:", error);
      }
      onClose();
    }
    else {
      alert(`Please provide valid ${data["status"]} Reason`)
    }

    // try {
    //   const datas = await updateBookingStatus(data, id);
    // } catch (error) {
    //   alert("Error while calling update API:", error);
    // }
  };

  // const handleGatewaySelection = (selectedGateway) => {
  //   setGatewayType(selectedGateway);
  //   setShowModal(false);
  //   sendPaymentLink(selectedGateway);
  // };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={isOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className="transfer-details-container">
          {transferData?.map((val, id) => {
            return (
              <>
                <div className="transfer-lead-passenger-details">
                  <h2>Lead Passenger Details</h2>
                  <div className="transfer-lead-passenger-detail">
                    <div className="lead-passenger-container">
                      <p>Name</p>
                      <h3 className="blur-text">{val.customerDetails?.name}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Phone Number</p>
                      <h3>+{val.customerDetails?.phone}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Email</p>
                      <h3 className="blur-text">{val.customerDetails?.email}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Total Passengers</p>
                      <h3>
                        {/* {parseInt(transfer?.[0]?.numberOfAdults) +
                          parseInt(transfer?.[0]?.numberOfChildrens)} */}
                        {parseInt(transfer?.[0]?.numberOfAdults ? transfer[0].numberOfAdults : 0) +
                          parseInt(transfer?.[0]?.numberOfChildrens ? transfer[0].numberOfChildrens : 0) +
                          parseInt(transfer?.[0]?.numberOfInfants ? transfer[0].numberOfInfants : 0)
                          }
                      </h3>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="transfer-lead-passenger-details">
                  <h2>Transfer Details</h2>
                  <div className="transfer-lead-passenger-detail">
                    <div className="lead-passenger-container">
                      <p>Trip Type</p>
                      <h3>{transfer?.[0]?.selectedTripType}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Vehicle</p>
                      <h3>{val.vehicle?.name}</h3>
                    </div>
                    <div
                      className="lead-passenger-container"
                    // style={{ width: "200px" }}
                    >
                      <p>Pickup</p>
                      <h3>{transfer?.[0]?.InOut[0]?.from}</h3>
                      {transfer?.[0]?.InOut[0]?.fromRemarks &&
                        <h5>( {transfer?.[0]?.InOut[0]?.fromRemarks} )</h5>
                      }
                    </div>
                    <div className="lead-passenger-container">
                      <p>Pickup Date & Time</p>
                      <h3 style={{ width: "200px" }}>
                        {transfer?.[0]?.InOut[0]?.arrivalPickupTime?.split("T")[0]}
                        &nbsp;
                        ({transfer?.[0]?.InOut[0]?.pickupTimeForArrival})
                      </h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Drop off</p>
                      <h3>{transfer?.[0]?.InOut[0]?.to}</h3>
                      {transfer?.[0]?.InOut[0]?.toRemarks &&
                        <h5>( {transfer?.[0]?.InOut[0]?.toRemarks} )</h5>
                      }
                    </div>
                    {(transfer?.[0]?.selectedTripType === 'ROUND_TRIP') && (
                      <div className="lead-passenger-container">
                        <p>Arrival Pickup Date & Time</p>
                        <h3 style={{ width: "200px" }}>{transfer?.[0]?.InOut[1]?.departurePickupTime?.split("T")[0]}
                          &nbsp;
                          ({transfer?.[0]?.InOut[1]?.pickupTimeForDeparture})
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="transfer-lead-passenger-details"
                  style={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}
                >
                  <div>
                    <h2>Agent Details</h2>
                    <div className="transfer-lead-passenger-detail">
                      <div className="lead-passenger-container">
                        <p>Name</p>
                        <h3 style={{ textTransform: "capitalize" }}>
                          {val.agentName}
                        </h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Contact Number</p>
                        <h3>+{val.agentPhoneNumber}</h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Agent Email</p>
                        <h3
                        >{val.agentEmail}</h3>
                      </div>
                    </div>
                  </div>
                  {val.vehicle.vehicleProviderName?.length > 0 && (
                    <div>
                      <h2>Vehicle Provider Details</h2>
                      <div className="transfer-lead-passenger-detail">
                        <div className="lead-passenger-container">
                          <p>Name</p>
                          <h3 style={{ textTransform: "capitalize" }}>
                            {val.vehicle.vehicleProviderName}
                          </h3>
                        </div>
                        <div className="lead-passenger-container">
                          <p>Mobile No.</p>
                          <h3>{val.vehicle.vehicleProviderMobile}</h3>
                        </div>
                        <div className="lead-passenger-container">
                          <p>Email</p>
                          <h3>{val.vehicle.vehicleProviderEmail}</h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="transfer-lead-passenger-details">
                    <h2>Price Breakup</h2>
                    <div className="transfer-lead-passenger-detail">
                      <div className="lead-passenger-container">
                        <p>Base fare</p>
                        <h3>AED {val.totalCost}</h3>
                      </div>
                      {/* <div className="lead-passenger-container">
                        <p>VAT</p>
                        <h3>5%</h3>
                      </div> */}
                      <div className="lead-passenger-container">
                        <p>Total fare</p>
                        <h3>AED {parseInt(val.totalCost)}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="transfer-lead-passenger-details">
                    <h2>Select Payment Gateway</h2>
                    <div className="transfer-lead-passenger-detail">
                      {/* {showModal1 && ( */}
                        <div className="modal">
                          {!(bookingStatus === 'Confirmed') &&
                            <div className="modal-content">
                              <select value={gatewayType}
                                onChange={(e) => {
                                  setGatewayType(e.target.value)
                                  // setShowModal(true)
                                  // setshowModal1(false)
                                }}
                              >
                                <option value="" disabled> Select Getway</option>
                                <option value="STRIPE">Stripe</option>
                                <option value="TELR">Telr</option>
                              </select>
                            </div>
                          }
                        </div>
                      {/* )} */}
                      {/* {showModal && (
                        <div>
                          <div className="lead-passenger-container">
                            <p>Send Link</p>
                            <button
                              id="payment-link"
                              onClick={() => {
                                handleGatewaySelection(gatewayType)
                              }}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      )} */}
                      {/* <div className="lead-passenger-container">
                        <button id="payment-status">{val.paymentStatus}</button>
                      </div> */}
                    </div>

                  </div>
                </div>
              </>
            );
          })}
          <div className="transfer-details-button">
            {/* <button
              id="cancel"
              onClick={() => {
                setReasonCancel(true);
              }}
              // disabled={bookingStatus === 'Confirmed'}
              // className={bookingStatus === 'Confirmed' ? "intensity" : ""}
            >
              Cancel
            </button> */}
            <button
              id="reject"
              onClick={() => {
                setReasonReject(true);
              }}
              disabled={(bookingStatus === 'Rejected' ||
                bookingStatus === 'Confirmed' 
                // bookingStatus === 'Awaiting Payment'
              )}
              className={(bookingStatus === 'Rejected' ||
                bookingStatus === 'Confirmed' 
                // bookingStatus === 'Awaiting Payment'
              ) ? "intensity" : ""}
            >
              {" "}
              <img src="/reject.png" />
              &nbsp; Reject
            </button>
            <button
              id="on-hold"
              onClick={() => {
                setReasonPutHold(true);
              }}
              disabled={(bookingStatus === 'On Hold' ||
                bookingStatus === 'Rejected' ||
                bookingStatus === 'Confirmed' 
                // bookingStatus === 'Awaiting Payment'
              )}
              className={(bookingStatus === 'On Hold' ||
                bookingStatus === 'Rejected' ||
                bookingStatus === 'Confirmed' 
                // bookingStatus === 'Awaiting Payment'
              ) ? "intensity" : ""}
            >
              <img src="/Pause.png" />
              &nbsp; On Hold
            </button>
            {/* <button
              id="approve"
              onClick={() => {
                setReasonPaymentLink(true);
              }}
              disabled={bookingStatus === 'Confirmed'}
              className={bookingStatus === 'Confirmed' ? "intensity" : ""}
            >
              <img src="/Approve.png" /> &nbsp;
              {bookingStatus === 'Pending' ? '  Approve' : '  Approved'}
            </button> */}
            {loading ? <div className="loader"></div> :
              <>
                {(bookingStatus === "Pending" || bookingStatus === "On Hold") &&
                  <button id="approve"
                    onClick={sendPaymentLink}
                  >
                    Send Payment Link
                  </button>
                }
                {bookingStatus === "Awaiting Payment" &&
                  <button id="approve"
                    onClick={() => {
                      sendPaymentLink();
                      SetResendCount(count + 1);
                    }}
                  >
                    Resend Payment Link
                  </button>
                }
                {bookingStatus === "Confirmed" &&
                  <button id="approve"
                    onClick={() => {
                      alert("Payment already received for this booking");
                      return;
                    }}
                  >
                    Payment Received
                  </button>
                }
                {bookingStatus === "Rejected" &&
                  <button id="approve"
                    onClick={() => {
                      alert("This request has been rejected");
                      return;
                    }}
                  >
                    Booking Rejected
                  </button>
                }
              </>
            }
          </div>
        </div>
        {reasonCancel && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for cancellation</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonCancel(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("Cancelled", reasonMessage);
                    checkMessage();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {raesonReject && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for Rejection</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonReject(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason for..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("Rejected", reasonMessage);
                    checkMessage();
                  }}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        )}
        {reasonPutHold && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for Putting Hold</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonPutHold(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("On Hold", reasonMessage);
                    checkMessage();
                  }}
                >
                  Put on Hold
                </button>
              </div>
            </div>
          </div>
        )}
        {reasonPaymentLink && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Send the Payment link for Confirmation</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonPaymentLink(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-button">
                {/* <button id="send-link-later">Send link later</button>{" "} */}
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <button
                  onClick={() => {
                    sendPaymentLink("STRIPE");
                  }}
                >
                  Send Payment link now
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItemButton>

          
        </List> */}
      </Dialog>
    </React.Fragment>
  );
}