
import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState } from "react";
import { token,APIPath } from "../../config/api";
import './Country.css';

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const CountryForm = ({ open, onClose, CountrylName}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [countryName,setCountryName]=useState('');

    const AddCountrylName =()=>{
      setIsLoading(true);
      fetch(`${APIPath}/api/v1/agency/country`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        mode: 'cors',
        body:JSON.stringify({"name": countryName,})
    })
        .then((res) => res.json())
        .then((data) => {
            alert(data.message)
            CountrylName();
            setIsLoading(false);
            onClose();
        })
        .catch((err) => {
            alert(err)
            setIsLoading(false)
        })
    }
    const handleCountryName=(e)=>{
        const name = e.target.value;
        const isAlphabetic = /^[a-zA-Z\s]*$/.test(name);
        if (isAlphabetic || name === "") {
            const sanitizedValue = name.replace(/^\s+|\s+(?=\s)/g, '');
            setCountryName(sanitizedValue)
        }
    }

    return (
        <StyledTableContainer component={Paper} open={open}>
            <div className="add-country-container">
                <div className="add-country-form">
                    <form className="add-country" 
                    onSubmit={(e)=>{
                        e.preventDefault();
                        AddCountrylName();
                        }}
                      >
                        <h2>Add Country</h2>
                        <div className="">
                            <div className="add-country-name">
                                <label>Country Name *</label>
                                <input
                                    type="text"
                                    placeholder="Type Country name..."
                                    name="name"
                                    maxLength={20}
                                    value={countryName}
                                    onChange={handleCountryName}
                                    required
                                />
                            </div>
                        </div>
                        <div className="add-country-btn">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <button onClick={onClose} id="cancel">Cancel</button>
                                    <button type="submit" id="addvehicle" >Add Country</button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </StyledTableContainer>

    );
}

export default CountryForm;