import * as React from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { token, APIPath } from "../../config/api";

import TransferBookingDetail from "./TransferBookingDetail";
import './TransferBooking.css'
import { styled } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import {
  Stack,
  Pagination,
} from "@mui/material";

const StyledPaginationContainer = styled("div")({
  background: "#fff",
  margin: " 2rem auto 0 auto",
  zIndex: 1,
  background: "none",
});

export default function TransferList({ searchText, bookingStatus }) {
  const [transferBookings, setTransferBookings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCreateFormOpen, setCreateFormOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(15);

  const [bookingId, setBookingId] = React.useState("");
  const transferList = () => {
    fetch(`${APIPath}/api/v1/agency/bookings/transfers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        if (bookingStatus === "Pending") {
          const pendigData = data.data.filter((data) => data.bookingStatus === "Pending");
          setTransferBookings(pendigData);
          setFilterdata(pendigData);
        }
        else if (bookingStatus === "Awaiting Payment") {
          const waitingData = data.data.filter(item => item.bookingStatus === "Awaiting Payment");
          setTransferBookings(waitingData);
          setFilterdata(waitingData)
        }
        else if (bookingStatus === "On Hold") {
          const holdData = data.data.filter(item => item.bookingStatus === "On Hold");
          setTransferBookings(holdData);
          setFilterdata(holdData)
        }
        else if (bookingStatus === "Confirmed") {
          const confirmData = data.data.filter(item => item.bookingStatus === "Confirmed");
          setTransferBookings(confirmData);
          setFilterdata(confirmData)
        }
        else if (bookingStatus === "Rejected") {
          const rejectedData = data.data.filter(item => item.bookingStatus === "Rejected");
          setTransferBookings(rejectedData);
          setFilterdata(rejectedData)
        }
        else {
          setTransferBookings(data.data);
          setFilterdata(data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching vehicle list:', error);
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    transferList()
  }, [bookingStatus]);

  // const filterdata =transferBookings && transferBookings?.filter(item =>
  //   item.bookingID.toLowerCase().includes(searchText.toLowerCase())
  // );
  const filterdata = transferBookings && transferBookings?.filter(item =>
    item.bookingID?.toLowerCase().includes(searchText.toLowerCase()) ||
    item.agentEmail?.toLowerCase().includes(searchText.toLowerCase())
);

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = filterdata && filterdata.slice(indexOfFirstItem, indexOfLastItem);

 

  // const filteredTransferdata = transferBookings.filter(item =>
  //   item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
  // );

  const handleCreateFormOpen = (_id) => {
    console.log("CREATEDDDDDDDDD!!!", _id);
    setBookingId(_id);
    setCreateFormOpen(true);
  };

  const handleCreateFormClose = () => {
    setCreateFormOpen(false);
    transferList()
  };
  const [filterdata1, setFilterdata] = React.useState([]);
  const handleDownload = () => {
    const BookingList = filterdata1.map(val => [
      val.bookingID,
      val.agentName,
      val.agentEmail,
      val.createdAt.split("T")[0],
      val.totalCost,
      val.bookingStatus
    ]);
    const headers = [
      "Booking Id",
      "Agent Name",
      "Agent Email",
      "Booking Date",
      "Total Cost",
      "Booking Status"
    ];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...BookingList]);
    XLSX.utils.book_append_sheet(wb, ws, 'Booking List');
    XLSX.writeFile(wb, 'Magical_Vacation_Booking_list.xlsx');

  }

  return <>
    {isLoading ? (
      <div className="loader"></div>) : (
      <>
        {currentItems?.length > 0 ? (
          <>
            <button id="download-btn" onClick={handleDownload}><img src="downloadicon.svg" />&nbsp;Download</button>
            <div className="package-list-container">
              <table>
                <thead>
                  <tr>
                    <th>Booking Id</th>
                    <th>Agent Name</th>
                    <th>Agent Email</th>
                    <th>Booking Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((val, id) => {
                    return <>
                      <tr key={id}>
                        <td>{val.bookingID}</td>
                        <td>{val.agentName}</td>
                        <td>{val.agentEmail}</td>
                        <td>{val.createdAt.split("T")[0]}</td>
                        <td>{val.totalCost}</td>
                        <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                          (val.bookingStatus === "On Hold" && "on-hold") ||
                          (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                          (val.bookingStatus === "Confirmed" && "confirm") ||
                          (val.bookingStatus === "Rejected" && "rejected")
                        }>{val.bookingStatus}</p></td>
                        <td>
                          <button
                            onClick={() => {
                              handleCreateFormOpen(val._id)
                            }}
                          >
                            <MdOutlineRemoveRedEye />
                          </button>
                        </td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="not-found-booking">
            <h2>No Record Found</h2>
          </div>
        )}
      </>
    )}

    {isCreateFormOpen && (
      <TransferBookingDetail
        isOpen={isCreateFormOpen}
        onClose={handleCreateFormClose}
        id={bookingId}
      // onPaymentLinkSent={handleRefresh}
      />
    )}
    <StyledPaginationContainer>
      <Stack spacing={1}>
        <Pagination
          count={Math.ceil((transferBookings && transferBookings.length) / perPage)}
          // count={1}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          variant="outlined"
          color="primary"
          boundaryCount={1}
        />
      </Stack>
    </StyledPaginationContainer>
  </>

}
