import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { IoIosSearch } from "react-icons/io";
import {
    Stack,
    Pagination,
} from "@mui/material";
import './Vehicle.css';
import Vehicle from "./Vehicle";
import { token,APIPath } from "../../config/api";


const StyledTableContainer = styled(TableContainer)(({theme, open }) => ({
    marginTop: "1%",
    marginLeft: open ? theme.spacing(29) : theme.spacing(7),
    width: open ? "80%" : "94%",
    transition: "margin-left 0.3s ease",
    background:"rgba(204,204,204,0.2)",
    padding:"1rem 1rem 1rem 1rem",
    boxShadow:"none",
}));
const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: "2rem auto 0 auto;",
    zIndex: 1,
    background: "none",
    display:"flex",
    justifyContent:"center"
});

const VehicleList = ({ open }) => {
    const [vehicleList, setVehicleList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [newVehicle, setNewVehicle] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);
    const [searchText,setsearchText]=React.useState('');

    const onClose = () => {
        setNewVehicle(false)
        loadVehicleList();
    }
    const onShow = () => {
        setNewVehicle(true)
    }
    const loadVehicleList = () => {
        fetch(`${APIPath}/api/v1/agency/vehicles`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setVehicleList(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching vehicle list:', error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadVehicleList();
    }, []);

    const filterdata =vehicleList && vehicleList?.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = filterdata && filterdata?.slice(indexOfFirstItem, indexOfLastItem);

    

      const deleteVehicle=(id)=>{
        let confirm = window.confirm("Are you sure you want to delete this Vehicle? This action cannot be undone")
        if(confirm){
            setIsLoading(true);
            fetch(`${APIPath}/api/v1/agency/vehicles?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
                mode: 'cors',
            })
                .then((res) => res.json())
                .then((data) => {
                    alert("Vehicle deleted");
                    loadVehicleList();
                    setIsLoading(false)
                })
                .catch((err) => {
                    alert(err)
                    setIsLoading(false)
                })
        }
        else {
            return;
        }

    }  

    return (
        <>
            <StyledTableContainer component={Paper} open={open}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                    <h2 style={{ fontSize: "18px" }}> Vehicle</h2>
                    <button className="add-hotel"
                        onClick={onShow}
                    >Add Vehicle</button>
                </div>
                <div className="package-search-table">
                <div className="package-search">
                    <input type="text" placeholder="Search.." value={searchText} onChange={(e) => {
                        setsearchText(e.target.value)
                    }} />
                    <IoIosSearch style={{ cursor: "pointer",color:"skyblue" }} />
                </div>
                {isLoading ? (
                    <div className="loader">

                    </div>
                ) : (currentItems?.length > 0 ? (
                    <div>
                        <div className="package-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sl. No.</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Owner Name</th>
                                        <th>Max. Pass.</th>
                                        <th>Max. Suitcase</th>
                                        <th>Max. Handbag</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems?.map((vehicle, id) => (
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            <td><img style={{ width: "50px", height: "40px" }}
                                                src={vehicle.images[0]} alt={vehicle.images[0]} /></td>
                                            <td>{vehicle.name}
                                                <p style={{fontSize:'12px',color:'blue'}}>{vehicle.vehicleType}</p>
                                            </td>
                                            <td>{vehicle.vehicleProviderName}</td>
                                            <td>{vehicle.maxPassenger}</td>
                                            <td>{vehicle.suitCaseAllowed}</td>
                                            <td>{vehicle.handbagAllowed}</td>
                                            <td> <button id="country-delete"
                                            onClick={()=>{
                                                deleteVehicle(vehicle._id)
                                            }}
                                            >
                                                <img src="./deleteicon.svg" />
                                            </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2>No data found</h2>
                    </div>
                )
                )}
                </div>
                <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((vehicleList && vehicleList?.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
                </StyledPaginationContainer>
            </StyledTableContainer>
            {newVehicle && <Vehicle onClose={onClose} onShow={onShow} />}
        </>
    );
}

export default VehicleList;
